/*
@File: Agcia - Angular 10 SEO & IT Company Template

* This file contains the styling for the actual tepmlate, this
is the file you need to edit to change the look of the
tepmlate.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

==== INDEX PAGE CSS

** - Default CSS
** - Preloader CSS
** - Header CSS
** - Navbar CSS
** - Main Banner CSS
** - Boxes Area CSS
** - About CSS
** - Features CSS
** - Services CSS
** - Analysis CSS
** - Project CSS
** - Project Details CSS
** - Working Process CSS
** - Team CSS
** - Testimonials CSS
** - FunFacts CSS
** - Check Your Website CSS
** - Pricing CSS
** - Partner CSS
** - Partner CSS
** - Blog CSS
** - Blog Details CSS
** - Page Title CSS
** - History CSS
** - Sidebar CSS
** - Pagination CSS
** - Subscribe CSS
** - FAQ CSS
** - Error Page CSS
** - Contact CSS
** - Footer CSS
** - Right Side Modal CSS
** - Go Top CSS

*/
/*=============================
Default CSS
===============================*/
.itajer-primary {
  color: #00a09d;
}

.itajer-secondary {
  color: #36595b;
}

.sb-chat .sb-popup-message {
  bottom: 88px !important;
}

.btn_itajer-primary {
  background-color: #00a09d;
  color: #ffffff;
  box-shadow: 0px 15px 25px 0px rgba(53, 90, 91, 0.3);
}
.btn_itajer-primary:hover, .btn_itajer-primary:focus {
  color: #00a09d;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0px 15px 25px 0px rgba(53, 90, 91, 0.3);
}
.btn_itajer-primary.focus, .btn_itajer-primary:focus {
  box-shadow: 0px 15px 25px 0px rgba(53, 90, 91, 0.3);
}
.btn_itajer-primary:not(:disabled):not(.disabled).active, .btn_itajer-primary:not(:disabled):not(.disabled):active, .btn_itajer-primary .show > .btn-primary.dropdown-toggle {
  color: #29c0b1;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn_itajer-primary:not(:disabled):not(.disabled).active:focus, .btn_itajer-primary:not(:disabled):not(.disabled):active:focus, .btn_itajer-primary .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0px 15px 25px 0px rgba(53, 90, 91, 0.3);
}
.btn_itajer-primary.disabled, .btn_itajer-primary:disabled {
  color: #ffffff;
  background-color: #29c0b1;
  border-color: #007bff;
  opacity: 1;
}
.btn_itajer-primary.disabled:hover, .btn_itajer-primary:disabled {
  color: #29c0b1;
  background-color: #ffffff;
}

.particles-js-canvas-el {
  z-index: -1;
}

section {
  background: #fff;
}

body {
  padding: 0;
  margin: 0;
  font-size: 15px;
  font-family: "Tajawal", sans-serif;
}

a {
  outline: 0 !important;
  color: #283a5e;
  text-decoration: none;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
a:hover, a:focus {
  color: #582C85;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

:focus {
  outline: 0 !important;
}

.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  vertical-align: middle;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
  line-height: 1;
  color: #00a09d;
}

p {
  color: #828893;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 0;
  margin-top: 0;
}

.bg-fbf9f8 {
  background: #fbf9f8;
}

.bg-fbf9f8 {
  background-color: #fbf9f8;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.mt-30 {
  margin-top: 30px;
}

/*btn*/
.btn {
  border-radius: 100px;
  box-shadow: 0px 15px 25px 0px rgba(88, 44, 133, 0.3);
  padding: 14px 40px;
  border: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
}

.btn-primary {
  background-color: #582C85;
  color: #ffffff;
}
.btn-primary:hover, .btn-primary:focus {
  color: #582C85;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-primary.focus, .btn-primary:focus {
  box-shadow: 0px 15px 25px 0px rgba(88, 44, 133, 0.3);
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary .show > .btn-primary.dropdown-toggle {
  color: #582C85;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0px 15px 25px 0px rgba(88, 44, 133, 0.3);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #ffffff;
  background-color: #582C85;
  border-color: #007bff;
  opacity: 1;
}
.btn-primary.disabled:hover, .btn-primary:disabled {
  color: #582C85;
  background-color: #ffffff;
}

/*Section Title*/
.section-title {
  margin-bottom: 60px;
  position: relative;
}
.section-title span {
  color: #582C85;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
}
.section-title h3 {
  text-transform: capitalize;
  max-width: 635px;
  line-height: 44px;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 17px;
  margin-top: 13px;
}
.section-title p {
  max-width: 635px;
}
.section-title .read-more-btn {
  position: absolute;
  left: 0;
  display: inline-block;
  font-size: 16px;
  top: 50%;
  transform: translateY(-50%);
  text-decoration: underline;
}
.section-title.text-center h3 {
  margin-left: auto;
  margin-right: auto;
}
.section-title.text-center p {
  margin-left: auto;
  margin-right: auto;
}

/*Form Control*/
.form-group {
  margin-bottom: 15px;
}

.form-control {
  height: 50px;
  font-size: 16px;
  padding: 10px 20px;
  border: 1px solid #f3f5fe;
  outline: 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background: #f3f5fe;
}
.form-control::placeholder {
  color: #6e6e6e !important;
}
.form-control:focus {
  color: #6e6e6e;
  background-color: #ffffff;
  outline: 0;
  box-shadow: unset;
  border-color: #582C85;
}

/*Owl Carousel btn*/
.owl-theme .owl-nav {
  margin-top: 0;
}
.owl-theme .owl-nav [class*=owl-] {
  color: #ffffff !important;
  font-size: 20px !important;
  transform: translateY(-50%);
  margin: 0;
  padding: 0 !important;
  background: #D6D6D6 !important;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  right: 40px;
  width: 40px;
  height: 40px;
  line-height: 42px !important;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.owl-theme .owl-nav [class*=owl-]:hover {
  background: #582C85 !important;
  color: #ffffff !important;
}
.owl-theme .owl-nav .owl-next {
  right: auto;
  left: 40px;
}
.owl-theme .owl-dots {
  line-height: 0.1;
  margin-top: 20px;
}
.owl-theme .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  margin: 0 4px;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #582C85;
}

/*=============================
Preloader CSS
===============================*/
.preloader-area {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 999999;
  left: 0;
  top: 0;
}
.preloader-area .spinner {
  margin: -85px auto 0 -65px;
  width: 65px;
  height: 65px;
  position: relative;
  text-align: center;
  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
  position: absolute;
  left: 50%;
  top: 50%;
}
.preloader-area .dot1, .preloader-area .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #582C85;
  border-radius: 100%;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
.preloader-area .dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
/*=============================
Top Header CSS
===============================*/
.top-header-area {
  background: #582C85;
  padding-top: 12px;
  padding-bottom: 12px;
}
.top-header-area ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.top-header-area ul li {
  display: inline-block;
}
.top-header-area .social-links li a {
  color: #ffffff;
  margin-right: 8px;
  border: none;
  width: auto;
  display: inline-block;
  height: auto;
  line-height: unset;
  border-radius: unset;
}
.top-header-area .social-links li a:hover {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}
.top-header-area .social-links li:first-child {
  color: #ffffff;
  font-weight: 700;
}
.top-header-area .header-info {
  text-align: left;
}
.top-header-area .header-info li {
  color: #ffffff;
  position: relative;
  font-weight: 600;
  padding-right: 15px;
  padding-left: 6px;
}
.top-header-area .header-info li::before {
  content: "";
  position: absolute;
  right: 3px;
  top: 4px;
  height: 14px;
  width: 2px;
  background-color: #ffffff;
  opacity: 0.3;
}
.top-header-area .header-info li:first-child {
  padding-right: 0;
}
.top-header-area .header-info li:first-child::before {
  display: none;
}
.top-header-area .header-info li:last-child {
  padding-left: 0;
}
.top-header-area .header-info li a {
  color: #ffffff;
  font-weight: 600;
}

/*=============================
Navbar CSS
===============================*/
.navbar {
  padding-left: 0;
  padding-right: 0;
  padding-top: 15px;
  padding-bottom: 15px;
}
.navbar.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  padding: 15px 0;
  background-color: #ffffff !important;
  -webkit-box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
.navbar .navbar-brand {
  margin-right: 0;
  margin-left: 1rem;
}

.bg-light {
  background-color: transparent !important;
}

.navbar-light .navbar-nav {
  padding-right: 0;
  margin-right: 50px;
}
.navbar-light .navbar-nav .nav-item {
  position: relative;
  margin-left: 13px;
  margin-right: 13px;
}
.navbar-light .navbar-nav .nav-item .nav-link {
  color: #283a5e;
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 0;
  padding-left: 0;
  font-size: 17px;
  font-weight: 600;
}
.navbar-light .navbar-nav .nav-item .nav-link:hover, .navbar-light .navbar-nav .nav-item .nav-link.active {
  color: #582C85;
}
.navbar-light .navbar-nav .nav-item .nav-link i {
  position: relative;
  top: 1px;
  display: inline-block;
  margin-right: -3px;
}
.navbar-light .navbar-nav .nav-item .dropdown-menu {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background: #ffffff;
  position: absolute;
  border: none;
  top: 100%;
  left: auto;
  right: 0;
  width: 250px;
  z-index: 99;
  display: block;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
  margin-top: 20px;
  text-align: right;
  padding-top: 13px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 13px;
}
.navbar-light .navbar-nav .nav-item .dropdown-menu .nav-item {
  margin-left: 0;
  margin-right: 0;
}
.navbar-light .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link {
  font-weight: 500;
  color: #283a5e;
  padding-top: 7px;
  padding-bottom: 7px;
}
.navbar-light .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link:hover, .navbar-light .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link.active {
  color: #582C85;
}
.navbar-light .navbar-nav .nav-item .dropdown-menu .nav-item .dropdown-menu {
  opacity: 0;
  visibility: hidden;
  top: 0;
  margin-top: 15px;
  right: 230px;
}
.navbar-light .navbar-nav .nav-item .dropdown-menu .nav-item .dropdown-menu .nav-item .dropdown-menu {
  opacity: 0;
  visibility: hidden;
  top: 0;
  margin-top: 15px;
  right: -230px;
}
.navbar-light .navbar-nav .nav-item .dropdown-menu .nav-item .dropdown-menu .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}
.navbar-light .navbar-nav .nav-item .dropdown-menu .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}
.navbar-light .navbar-nav .nav-item:last-child {
  margin-left: 0;
}
.navbar-light .navbar-nav .nav-item:first-child {
  margin-right: 0;
}
.navbar-light .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}
.navbar-light .others-option {
  margin-right: auto;
  display: flex;
  align-items: center;
}
.navbar-light .others-option .lang-switcher select {
  border: 1px solid #eeeeee;
  padding: 2px;
  background: transparent;
  cursor: pointer;
}
.navbar-light .others-option .call-us {
  margin-right: 15px;
}
.navbar-light .others-option .call-us span {
  display: inline-block;
  color: #828893;
  font-size: 16px;
  font-weight: 500;
}
.navbar-light .others-option .call-us a {
  display: inline-block;
  color: #582C85;
  margin-right: 1px;
  position: relative;
  top: 0.5px;
  font-size: 16px;
  font-weight: 500;
}
.navbar-light .others-option .call-us a:hover {
  color: #283a5e;
}
.navbar-light .others-option .modal-taggle-button {
  margin-right: 15px;
}
.navbar-light .others-option .modal-taggle-button a {
  display: block;
  width: 35px;
  position: relative;
  height: 18px;
}
.navbar-light .others-option .modal-taggle-button a::before {
  content: "";
  width: 30px;
  height: 2px;
  background: #582C85;
  position: absolute;
  left: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  bottom: 8px;
}
.navbar-light .others-option .modal-taggle-button a span {
  display: block;
}
.navbar-light .others-option .modal-taggle-button a span::before, .navbar-light .others-option .modal-taggle-button a span::after {
  content: "";
  width: 35px;
  height: 2px;
  background: #582C85;
  position: absolute;
  left: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.navbar-light .others-option .modal-taggle-button a span::after {
  bottom: 0;
}
.navbar-light .others-option .modal-taggle-button a span::before {
  top: 0;
}
.navbar-light .others-option .modal-taggle-button a:hover::before {
  width: 35px;
}

.navbar-light .navbar-toggler {
  border-color: #283a5e;
  color: #283a5e;
  border-radius: 0;
}
.navbar-light .navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

@media only screen and (max-width: 991px) {
  .navbar .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .navbar-light .navbar-nav {
    background-color: #ffffff;
    border: 1px solid #eeeeee;
    overflow-y: scroll;
    height: 350px;
    padding: 10px 20px;
    margin-top: 15px;
    margin-right: 0;
  }
  .navbar-light .navbar-nav .nav-item .nav-link {
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-light .navbar-nav .nav-item .dropdown-menu {
    box-shadow: unset;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    right: 0;
    opacity: 1;
    visibility: visible;
    margin-top: 0;
    border: 1px solid #eeeeee;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
  }
  .navbar-light .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link {
    padding: 5px 10px;
    font-size: 15px;
  }
  .navbar-light .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link.active, .navbar-light .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link:hover, .navbar-light .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link:focus {
    padding-right: 10px;
  }
  .navbar-light .navbar-nav .nav-item .dropdown-menu .nav-item .dropdown-menu {
    right: 10px;
    top: 0 !important;
    opacity: 1;
    visibility: visible;
    margin-top: 2px;
    border-left: none;
  }
  .navbar-light .navbar-nav .nav-item .dropdown-menu .nav-item:hover .dropdown-menu {
    top: 0;
  }
  .navbar-light .navbar-nav .nav-item:hover .dropdown-menu {
    top: 0;
  }
  .navbar-light .others-option {
    display: none;
  }
}
/*=============================
Main Banner CSS
===============================*/
.main-banner {
  position: relative;
  height: 730px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero-bg-1 {
  background-image: url(../../assets/img/bg-pattern-2.png);
}

.circle-pattern-1 {
  -webkit-clip-path: circle(50% at 60% 46%);
  clip-path: circle(50% at 60% 46%);
  background: #fffaf8;
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
  -webkit-animation: movebounce 3s linear infinite;
  animation: movebounce 3s linear infinite;
}

.hero-content h1 {
  line-height: 1.1;
  font-size: 45px;
  font-weight: 700;
}
.hero-content h1 span {
  color: #582C85;
  position: relative;
  z-index: 1;
}
.hero-content h1 span::after {
  content: "";
  position: absolute;
  bottom: 12px;
  left: 0;
  width: 100%;
  height: 0px;
  z-index: -1;
  background: #283a5e;
}
.hero-content p {
  font-size: 19px;
  margin-bottom: 35px;
  margin-top: 25px;
}
.hero-content .btn {
  margin-right: 25px;
}
.hero-content .video-btn {
  position: relative;
  padding-right: 48px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 900;
}
.hero-content .video-btn i {
  margin-left: 12px;
  font-size: 35px;
  position: absolute;
  right: 0;
  bottom: -6px;
}

.hero-image {
  position: absolute;
  left: -100px;
  bottom: 30px;
  width: 725px;
}

.main-banner-two {
  height: 750px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.main-banner-three {
  height: 630px;
  overflow: hidden;
  position: relative;
  background-image: url(../../assets/img/bg-pattern-2.png);
}
.main-banner-three.hero-bg-1 {
  background-image: url(../../assets/img/bg-pattern-2.png);
}

.main-banner-four {
  height: 630px;
  position: relative;
  background-position: center;
  background-size: cover;
  z-index: 1;
  background-repeat: no-repeat;
}
.main-banner-four::before {
  content: "";
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  opacity: 0.9;
}
.main-banner-four .hero-content {
  margin-top: 30px;
}
.main-banner-four .hero-content h1 {
  text-transform: capitalize;
}

.item-bg-1 {
  background-image: url(../../assets/img/bg2.png);
}

.item-bg-2 {
  background-image: url(../../assets/img/bg2.png);
}

.item-bg-3 {
  background-image: url(../../assets/img/bg2.png);
}

::-webkit-input-placeholder {
  text-align: right;
}

/* mozilla solution */
input:-moz-placeholder {
  text-align: right;
}

/*=============================
Boxes CSS
===============================*/
.box {
  background: #582C85;
  padding: 0 30px;
  height: 110px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
}
.box h3 {
  color: #ffffff;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 700;
}
.box span {
  font-size: 100px;
  color: rgba(255, 255, 255, 0.1);
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: -1;
}
.box.bg {
  background: #e6541b;
}

.boxes-area .owl-thumbs {
  overflow: hidden;
}
.boxes-area .owl-thumb-item {
  float: left;
  width: 33.333%;
}

/*=============================
About CSS
===============================*/
.about-area {
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-image: url(../../assets/img/bg-pattern.png);
  background-attachment: fixed;
}

.single-box {
  text-align: center;
  background: #ffffff;
  padding: 25px;
  border-radius: 5px;
  -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
}
.single-box:hover {
  background: #582C85;
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.single-box:hover p, .single-box:hover h3 {
  color: #ffffff;
}
.single-box:hover .read-more-btn {
  color: #ffffff;
}
.single-box:hover .read-more-btn::before {
  background: #ffffff;
}
.single-box .icon {
  background: #f4f6fd;
  display: inline-block;
  width: 100px;
  height: 100px;
  font-size: 40px;
  color: #582C85;
  line-height: 100px;
  -webkit-clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
}
.single-box h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 30px;
}
.single-box p {
  margin-bottom: 20px;
}
.single-box p, .single-box h3 {
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
}

.read-more-btn {
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  color: #582C85;
  z-index: 1;
  display: inline-block;
}
.read-more-btn::before {
  content: "";
  position: absolute;
  right: 50px;
  bottom: 11px;
  width: 55px;
  height: 2px;
  background: #582C85;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
  z-index: -1;
}
.read-more-btn:hover, .read-more-btn:focus {
  color: #283a5e;
}
.read-more-btn:hover::before, .read-more-btn:focus::before {
  right: -20px;
  opacity: 1;
  visibility: visible;
}

.about-inner-area {
  margin-top: 80px;
}

.about-image {
  position: relative;
  z-index: 1;
}
.about-image .col-lg-6:first-child .image img {
  border-radius: 0 25px 0 0;
}
.about-image .col-lg-6:nth-child(2) .image img {
  border-radius: 25px 0 0 0;
}
.about-image .col-lg-6:nth-child(3) .image img {
  border-radius: 0 0 25px 0;
}
.about-image .col-lg-6:last-child .image img {
  border-radius: 0 0 0 25px;
}
.about-image .image img {
  box-shadow: 0px 10px 20px 0px rgba(130, 136, 147, 0.15);
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}
.about-image .image:hover img {
  -webkit-animation: movebounce 3s linear infinite;
  animation: movebounce 2s linear infinite;
}

.about-text {
  padding-right: 30px;
}
.about-text span {
  color: #582C85;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
}
.about-text h3 {
  margin: 15px 0 20px;
  line-height: 40px;
  font-size: 40px;
  font-weight: 700;
}
.about-text p {
  margin-bottom: 20px;
}
.about-text .read-more-btn {
  margin-top: 10px;
}

.circle-pattern-2 {
  height: 39em;
  position: absolute;
  width: 42em;
  bottom: -10px;
  right: -10px;
  background: #00a09d;
  border-radius: 50% 50% 50% 50%/60% 60% 40% 40%;
  -webkit-animation: movebounce 3s linear infinite;
  animation: movebounce 3s linear infinite;
  opacity: 0.1;
  z-index: -1;
}

.rocket {
  position: absolute;
  left: -120px;
  bottom: 20rem;
  z-index: -1;
}
.rocket img {
  width: 85%;
  -webkit-animation: movebounce 3s linear infinite;
  animation: movebounce 3s linear infinite;
}

.vector {
  position: absolute;
  left: -13rem;
  bottom: -14rem;
  -webkit-animation: movebounce 3s linear infinite;
  animation: movebounce 3s linear infinite;
  z-index: -1;
  opacity: 0.2;
}

@keyframes movebounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes moveleftbounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(15px);
  }
  100% {
    transform: translateX(0px);
  }
}
/*=============================
Features CSS
===============================*/
.features-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.features-area .about-image {
  margin-top: 0;
}
.features-area .about-text {
  margin-top: 50px;
}
.features-area .rocket {
  bottom: 45rem;
}

.features-text h3 {
  text-transform: capitalize;
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 700;
}
.features-text ul {
  padding: 0;
  margin: 20px 0 25px;
  list-style-type: none;
}
.features-text ul li {
  position: relative;
  color: #828893;
  margin-bottom: 15px;
  padding-right: 25px;
}
.features-text ul li::before {
  content: "";
  position: absolute;
  right: 0;
  top: 3px;
  width: 15px;
  height: 15px;
  background: #582C85;
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.features-text ul li:last-child {
  margin-bottom: 0;
}

.single-features i {
  background: #f4f6fd;
  display: inline-block;
  text-align: center;
  width: 80px;
  height: 80px;
  font-size: 35px;
  color: #582C85;
  line-height: 80px;
  -webkit-clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
}
.single-features h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 30px;
}
.single-features p {
  margin-bottom: 20px;
}
.single-features:hover i {
  background: #582C85;
  color: #ffffff;
}

.features-area-three {
  position: relative;
  z-index: 1;
  padding-bottom: 55px;
}

.features-box {
  margin-bottom: 45px;
  position: relative;
  padding-right: 75px;
}
.features-box i {
  background: #f4f6fd;
  display: inline-block;
  width: 55px;
  height: 55px;
  font-size: 30px;
  color: #582C85;
  line-height: 60px;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
}
.features-box h3 {
  margin-bottom: 12px;
  font-size: 22px;
  font-weight: 700;
}
.features-box p {
  margin-bottom: 20px;
}
.features-box:hover i {
  background: #582C85;
  color: #ffffff;
}

/*=============================
Services CSS
===============================*/
.services-area {
  padding-bottom: 70px;
  position: relative;
  z-index: 1;
}
.services-area.bg {
  background-image: url(../../assets/img/bg-pattern-2.png);
  background-attachment: fixed;
}

.single-services {
  text-align: center;
  background: #ffffff;
  padding: 25px;
  margin-bottom: 30px;
  border-radius: 5px;
  -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
}
.single-services:hover {
  background: #582C85;
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.single-services:hover .icon {
  border-radius: 50%;
}
.single-services:hover p, .single-services:hover h3 {
  color: #ffffff;
}
.single-services:hover .read-more-btn {
  color: #ffffff;
}
.single-services:hover .read-more-btn::before {
  background: #ffffff;
}
.single-services .icon {
  background: #f4f6fd;
  display: inline-block;
  width: 80px;
  height: 80px;
  font-size: 40px;
  color: #582C85;
  line-height: 80px;
  border-radius: 5px;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
}
.single-services h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 25px;
}
.single-services p {
  margin-bottom: 20px;
}
.single-services p, .single-services h3 {
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
}

.services-box {
  text-align: center;
  background: #ffffff;
  padding: 25px;
  margin-bottom: 30px;
  border-radius: 5px;
  -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
}
.services-box .icon {
  border: 1px solid #582C85;
  display: inline-block;
  width: 80px;
  height: 80px;
  font-size: 40px;
  position: relative;
  color: #582C85;
  line-height: 80px;
  border-radius: 50%;
  z-index: 1;
  margin: 20px 0 20px;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
}
.services-box .icon::before {
  display: block;
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  top: -21px;
  left: -21px;
  z-index: -1;
  border: 0.5em solid #ffffff;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
}
.services-box h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 30px;
}
.services-box p {
  margin-bottom: 20px;
}
.services-box:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.services-box:hover .icon {
  color: #ffffff;
  background-color: #582C85;
}

/*=============================
Analysis CSS
===============================*/
.analysis-area {
  position: relative;
  overflow: hidden;
}

.analysis-form {
  background: #ffffff;
  -webkit-box-shadow: 5px 7px 30px rgba(72, 73, 121, 0.15);
  box-shadow: 5px 7px 30px rgba(72, 73, 121, 0.15);
  padding: 40px;
  border-radius: 5px;
  text-align: center;
}
.analysis-form h3 {
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 700;
}
.analysis-form form .btn {
  display: block;
  width: 100%;
  padding: 13px 40px;
  border-radius: 5px;
  margin-top: 20px;
}

.analysis-text {
  position: relative;
  z-index: 1;
  padding-right: 25px;
}
.analysis-text span {
  color: #582C85;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
}
.analysis-text h3 {
  margin: 13px 0 20px;
  line-height: 45px;
  font-size: 40px;
  font-weight: 700;
}
.analysis-text ul {
  padding: 0;
  margin: 20px 0 0;
  list-style-type: none;
}
.analysis-text ul li {
  position: relative;
  color: #828893;
  margin-bottom: 15px;
  padding-right: 30px;
}
.analysis-text ul li::before {
  content: "";
  position: absolute;
  right: 0;
  top: 6px;
  width: 17px;
  height: 17px;
  background: #582C85;
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.analysis-text ul li:last-child {
  margin-bottom: 0;
}

.pattern-1 {
  position: absolute;
  bottom: 0;
  left: 0;
}

/*=============================
Project CSS
===============================*/
.shorting-menu {
  display: inline-block;
  -webkit-box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  padding: 18px 20px;
  margin-bottom: 40px;
  border-radius: 50px;
}
.shorting-menu.text-center {
  max-width: 700px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.shorting-menu .filter {
  border: none;
  background: #f4f4f4;
  padding: 9px 24px;
  text-transform: capitalize;
  cursor: pointer;
  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
  border-radius: 30px;
  color: #283a5e;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
}
.shorting-menu .filter.mixitup-control-active, .shorting-menu .filter:hover {
  background: #582C85;
  color: #ffffff;
}
.shorting-menu .filter:last-child {
  margin-left: 0;
}

.single-work {
  overflow: hidden;
  position: relative;
}
.single-work::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #283a5e;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.single-work .work-content {
  position: absolute;
  bottom: -115px;
  width: 100%;
  background: #ffffff;
  left: 0;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  opacity: 0;
  visibility: hidden;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.single-work .work-content h3 {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 600;
}
.single-work .work-content ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.single-work .work-content ul li {
  display: inline-block;
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
}
.single-work .work-content ul li a {
  display: inline-block;
}
.single-work .work-content ul li::before {
  content: "";
  position: absolute;
  left: -10px;
  top: 10px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #283a5e;
}
.single-work .work-content ul li:last-child::before {
  display: none;
}
.single-work .popup-btn, .single-work .link-btn {
  position: absolute;
  top: 0;
  right: 40px;
  width: 45px;
  text-align: center;
  height: 45px;
  line-height: 45px;
  border-radius: 50%;
  z-index: 2;
  color: #ffffff;
  font-size: 22px;
  opacity: 0;
  visibility: hidden;
  border: 1px solid #ffffff;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.single-work .popup-btn:hover, .single-work .link-btn:hover {
  background: #582C85;
  border-color: #582C85;
}
.single-work .link-btn {
  top: 40px;
  right: auto;
  left: 0;
}
.single-work:hover .link-btn {
  left: 40px;
}
.single-work:hover::before {
  opacity: 0.8;
  visibility: visible;
}
.single-work:hover .work-content {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}
.single-work:hover .popup-btn, .single-work:hover .link-btn {
  top: 40px;
  opacity: 1;
  visibility: visible;
  -webkit-animation: movebounce 3s linear infinite;
  animation: movebounce 3s linear infinite;
}

.project-slides.owl-theme .owl-dots {
  margin-top: 45px !important;
}

/*=============================
Project Details CSS
===============================*/
.project-details {
  margin-top: 35px;
}
.project-details h3 {
  margin-bottom: 15px;
  font-size: 25px;
  font-weight: 700;
}
.project-details p {
  font-size: 16px;
  margin-bottom: 15px;
}
.project-details ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.project-details ul.features {
  margin-top: 20px;
}
.project-details ul.features li {
  position: relative;
  color: #828893;
  margin-bottom: 10px;
  padding-right: 24px;
}
.project-details ul.features li::before {
  content: "";
  position: absolute;
  right: 0;
  top: 4px;
  width: 15px;
  height: 15px;
  background: #582C85;
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.project-details ul.features li:last-child {
  margin-bottom: 0;
}

.project-info ul li {
  position: relative;
  padding-right: 50px;
  margin-bottom: 20px;
  color: #828893;
}
.project-info ul li:last-child {
  margin-bottom: 0;
}
.project-info ul li i {
  position: absolute;
  right: 0;
  top: 5px;
  color: #582C85;
  font-size: 30px;
}
.project-info ul li span {
  display: block;
  color: #283a5e;
  font-size: 18px;
  font-weight: 700;
}

/*=============================
Working Process CSS
===============================*/
.single-work-process {
  text-align: center;
  background: #ffffff;
  padding: 25px;
  border-radius: 5px;
  position: relative;
  -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
}
.single-work-process::before {
  content: "";
  position: absolute;
  z-index: 1;
  background: url(../../assets/img/arrow-big.png) no-repeat center center;
  width: 217px;
  height: 210px;
  top: -20px;
  left: -120px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.single-work-process .icon {
  background: #f4f6fd;
  display: inline-block;
  width: 80px;
  height: 80px;
  position: relative;
  z-index: 1;
  font-size: 40px;
  color: #582C85;
  line-height: 80px;
  -webkit-clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
}
.single-work-process .icon::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0;
  height: 0;
  background: #582C85;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
}
.single-work-process h3 {
  margin-top: 30px;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 700;
}

.col-lg-3:nth-child(4) .single-work-process::before {
  display: none;
}

/*=============================
Team CSS
===============================*/
.team-area {
  padding-bottom: 70px;
}

.single-team {
  text-align: center;
  background: #ffffff;
  -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 25px 15px;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
}
.single-team img {
  border-radius: 50%;
}
.single-team .team-content {
  margin-top: 25px;
}
.single-team .team-content h3 {
  margin-bottom: 8px;
  font-size: 22px;
  font-weight: 700;
}
.single-team .team-content span {
  display: block;
  color: #828893;
}
.single-team .team-content ul {
  padding: 0;
  margin: 15px 0 0;
  list-style-type: none;
}
.single-team .team-content ul li {
  display: inline-block;
  margin: 0 4px;
}
.single-team .team-content ul li a {
  display: block;
}
.single-team .team-content h3, .single-team .team-content span, .single-team .team-content ul li a {
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
}
.single-team:hover {
  background: #582C85;
}
.single-team:hover .team-content h3, .single-team:hover .team-content span, .single-team:hover .team-content ul li a {
  color: #ffffff;
}

/*=============================
Testimonial CSS
===============================*/
.testimonial-area {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.testimonial-area .pattern-1 {
  left: auto;
  right: 0;
  bottom: auto;
  top: 0;
}

.single-feedback {
  background: #ffffff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
  padding: 20px;
  z-index: 1;
  border-radius: 5px;
  position: relative;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: 30px;
  margin-top: 25px;
}
.single-feedback .client-info {
  position: relative;
  padding-right: 118px;
  margin-bottom: 30px;
}
.single-feedback .client-info .img {
  position: absolute;
  right: 0;
  top: -45px;
  width: 100px;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.single-feedback .client-info .img img {
  border-radius: 50%;
}
.single-feedback .client-info h4 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
}
.single-feedback .client-info span {
  display: block;
  font-size: 15px;
  color: #828893;
  margin-top: 5px;
}
.single-feedback p, .single-feedback .client-info h4, .single-feedback .client-info span {
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.single-feedback i {
  font-size: 104px;
  position: absolute;
  top: 50%;
  right: 50%;
  color: #6f8ba4;
  z-index: -1;
  opacity: 0.1;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-top: -6px;
  margin-right: -70px;
}
.single-feedback:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  background: #582C85;
}
.single-feedback:hover .client-info .img {
  top: -33px;
  -webkit-animation: movebounce 3s linear infinite;
  animation: movebounce 3s linear infinite;
}
.single-feedback:hover p, .single-feedback:hover .client-info h4, .single-feedback:hover .client-info span {
  color: #ffffff;
}
.single-feedback:hover i {
  color: #ffffff;
  opacity: 0.14;
}

/*=============================
FunFacts CSS
===============================*/
.funfacts-area {
  position: relative;
  background: #582C85;
  z-index: 1;
}
.funfacts-area::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
  background-image: url(../../assets/img/bg-pattern-2.png);
}

.fun-fact {
  position: relative;
  padding-right: 65px;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.fun-fact i {
  font-size: 45px;
  color: #ffffff;
  position: absolute;
  right: 0;
  top: 5px;
  -webkit-animation: movebounce 3s linear infinite;
  animation: movebounce 3s linear infinite;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.fun-fact h3 {
  direction: ltr;
  text-align: right;
  color: #ffffff;
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 45px;
  font-weight: 700;
}
.fun-fact h3 .sign {
  display: inline-block;
  font-size: 40px;
}
.fun-fact p {
  font-size: 18px;
  color: #ffffff;
  display: block;
}
.fun-fact:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

/*=============================
Check Your Website CSS
===============================*/
.check-your-website {
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: #582C85;
}
.check-your-website::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
  background-image: url(../../assets/img/bg-pattern-2.png);
}
.check-your-website::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(../../assets/img/seoscore.png) center center;
  z-index: -1;
  opacity: 0.7;
  -webkit-animation: movebounce 3s linear infinite;
  animation: movebounce 3s linear infinite;
}

.check-your-website-content {
  position: relative;
  text-align: center;
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
}
.check-your-website-content h2 {
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 700;
}
.check-your-website-content h2 span {
  color: #283a5e;
  display: inline-block;
}
.check-your-website-content p {
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 30px;
}
.check-your-website-content .form-control {
  border-radius: 50px;
  border: none;
  height: 60px;
}
.check-your-website-content .btn {
  margin-top: 25px;
  padding: 17px 40px 15px;
}
.check-your-website-content .btn-primary {
  background: #ffffff;
  color: #582C85;
}
.check-your-website-content .btn-primary:hover, .check-your-website-content .btn-primary:focus {
  background: #283a5e;
  color: #ffffff;
}

/*=============================
Pricing CSS
===============================*/
.pricing-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.pricing-area .pattern-1 {
  left: auto;
  right: 0;
  bottom: auto;
  top: 0;
}

.tab-slider-nav {
  text-align: center;
  overflow: hidden;
  width: 100%;
  margin-bottom: 40px;
}

.tab-slider-tabs {
  display: inline-block;
  margin-bottom: 0;
  padding-right: 0;
  list-style: none;
  position: relative;
  border-radius: 35px;
  background: #ffffff;
}
.tab-slider-tabs::after {
  content: "";
  width: 50%;
  background: #582C85;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 35px;
}
.tab-slider-tabs.slide::after {
  right: 50%;
}

.tab-slider-trigger {
  font-weight: 700;
  color: #222222;
  text-transform: uppercase;
  padding: 11px 20px 10px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  display: inline-block;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tab-slider-trigger.active {
  color: #ffffff;
}

.tab-slider-container .row {
  width: 100%;
}

.pricingTable {
  padding-bottom: 30px;
  background: #ffffff;
  text-align: center;
  z-index: 1;
  position: relative;
  border-radius: 5px;
  background-image: url(../../assets/img/patt.png);
  background-position: center center;
  overflow: hidden;
  -webkit-box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.pricingTable .pricingTable-header {
  padding: 30px 15px 45px;
  background: #582C85;
  -webkit-clip-path: polygon(50% 100%, 100% 60%, 100% 0, 0 0, 0 60%);
  clip-path: polygon(50% 100%, 100% 60%, 100% 0, 0 0, 0 60%);
  position: relative;
}
.pricingTable .pricingTable-header::before {
  content: "";
  width: 400px;
  height: 400px;
  border-radius: 50%;
  position: absolute;
  right: -50%;
  top: -130%;
  background: repeating-radial-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.2) 20%);
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.pricingTable .title {
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: 700;
}
.pricingTable .price-value {
  display: block;
  color: #ffffff;
  margin-bottom: 30px;
  font-size: 40px;
  font-weight: 700;
}
.pricingTable .price-value span {
  font-size: 15px;
  color: #ffffff;
  margin-left: -10px;
}
.pricingTable .pricing-content {
  padding: 30px 25px;
  margin-bottom: 0;
  list-style: none;
}
.pricingTable .pricing-content li {
  color: #283a5e;
  text-transform: capitalize;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 12px;
  padding-bottom: 12px;
  font-size: 15px;
  font-weight: 600;
}
.pricingTable .pricing-content li:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.pricingTable:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.pricingTable:hover .pricingTable-header::before {
  right: 50%;
}

.pricing-plan {
  background: #ffffff;
  padding-bottom: 20px;
  position: relative;
  -webkit-box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  text-align: center;
  z-index: 1;
  margin-top: 20px;
  border-radius: 5px;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.pricing-plan h3 {
  padding: 20px;
  margin-bottom: 50px;
  background: #582C85;
  color: #ffffff;
  border-radius: 5px 5px 0 0;
  font-size: 22px;
  font-weight: 700;
}
.pricing-plan h3 span {
  display: block;
  color: #582C85;
  background: #ffffff;
  height: 100px;
  width: 100px;
  margin: 20px auto -65px;
  border-radius: 50%;
  box-shadow: 10px 15px 30px 0 rgba(72, 73, 121, 0.15);
  line-height: 100px;
  font-size: 30px;
  font-weight: 700;
}
.pricing-plan ul {
  margin: 80px 0 20px;
  padding: 0;
  list-style: none;
}
.pricing-plan ul li {
  border-top: 1px solid #eeeeee;
  padding: 12px 0;
  color: #283a5e;
  font-weight: 600;
}
.pricing-plan ul li:last-child {
  padding-bottom: 0;
}
.pricing-plan#most-popular {
  top: -20px;
  padding: 30px 0 50px;
  -webkit-box-shadow: 10px 15px 30px 0 rgba(72, 73, 121, 0.15);
  box-shadow: 10px 15px 30px 0 rgba(72, 73, 121, 0.15);
  z-index: 2;
}
.pricing-plan#most-popular h3 {
  margin-top: -30px;
  padding-top: 30px;
}
.pricing-plan:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

/*=============================
Services Details Area CSS
===============================*/
.services-details-overview {
  align-items: center !important;
  display: flex;
  flex-wrap: wrap;
  margin-right: -25px;
  margin-left: -25px;
  margin-bottom: 60px;
}
.services-details-overview:last-child {
  margin-bottom: 0;
}
.services-details-overview .services-details-desc {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 25px;
  padding-left: 25px;
}
.services-details-overview .services-details-desc h3 {
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 700;
}
.services-details-overview .services-details-desc .features-text {
  margin-top: 25px;
}
.services-details-overview .services-details-desc .features-text h4 {
  margin-bottom: 15px;
  font-size: 17px;
  font-weight: 600;
}
.services-details-overview .services-details-desc .features-text h4 i {
  font-size: 25px;
  color: #582C85;
  position: relative;
  top: 1px;
}
.services-details-overview .services-details-image {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 15px;
  padding-left: 15px;
}

/*=============================
Partner CSS
===============================*/
.partner-area {
  overflow: hidden;
  z-index: 1;
  position: relative;
}

.partner-item {
  text-align: center;
}
.partner-item a {
  display: block;
}
.partner-item a img {
  display: inline-block !important;
  width: auto !important;
}

.partner-slides.owl-theme .owl-dots {
  margin-top: 40px !important;
}

/*=============================
Blog CSS
===============================*/
.blog-area {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.blog-area .pattern-1 {
  bottom: auto;
  top: 0;
}

.single-blog-item {
  position: relative;
  padding: 25px;
  margin-bottom: 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.single-blog-item::before {
  content: "";
  position: absolute;
  left: 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.66;
}
.single-blog-item span, .single-blog-item p, .single-blog-item h3 {
  position: relative;
  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.single-blog-item span {
  font-weight: 600;
  color: #ffffff;
}
.single-blog-item h3 {
  line-height: 1.4;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 15px;
}
.single-blog-item h3 a {
  display: inline-block;
  color: #ffffff;
}
.single-blog-item h3 a:hover {
  color: #582C85 !important;
}
.single-blog-item p {
  color: #ffffff;
  opacity: 0.95;
}
.single-blog-item .link-btn {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 45px;
  height: 40px;
  display: inline-block;
  line-height: 44px;
  text-align: center;
  box-shadow: 0px 0px 25px 0px rgba(0, 27, 103, 0.3);
  border-radius: 0 50% 0 0;
  background-color: #ffffff;
  opacity: 0;
  visibility: hidden;
}
.single-blog-item:hover .link-btn {
  opacity: 1;
  visibility: visible;
}
.single-blog-item.bg-1 {
  background-image: url(../../assets/img/blog-img1.jpg);
}
.single-blog-item.bg-2 {
  background-image: url(../../assets/img/blog-img2.jpg);
}
.single-blog-item.bg-3 {
  background-image: url(../../assets/img/blog-img3.jpg);
}
.single-blog-item.bg-4 {
  background-image: url(../../assets/img/blog-img4.jpg);
}
.single-blog-item.bg-5 {
  background-image: url(../../assets/img/blog-img5.jpg);
}
.single-blog-item.bg-6 {
  background-image: url(../../assets/img/blog-img6.jpg);
}

.single-blog-post {
  margin-bottom: 30px;
  border-radius: 5px;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.single-blog-post .blog-image {
  display: block;
}
.single-blog-post .blog-post-content {
  margin-top: 25px;
}
.single-blog-post .blog-post-content span {
  font-weight: 600;
}
.single-blog-post .blog-post-content h3 {
  line-height: 1.4;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 12px;
  margin-top: 12px;
}
.single-blog-post .blog-post-content h3 a {
  display: inline-block;
}
.single-blog-post .blog-post-content p {
  margin-bottom: 18px;
}

/*=============================
Blog Details CSS
===============================*/
.blog-details .thumb {
  position: relative;
}
.blog-details .date {
  position: absolute;
  right: 0;
  bottom: 0;
  text-transform: uppercase;
  background: #582C85;
  color: #ffffff;
  padding: 12px 20px 10px;
  border-radius: 10px 0 0 0;
  font-size: 22px;
  font-weight: 700;
}

.blog-details-heading {
  margin: 30px 0 0;
}
.blog-details-heading h3 {
  margin-bottom: 15px;
  font-size: 25px;
  font-weight: 700;
}
.blog-details-heading ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.blog-details-heading ul li {
  display: inline-block;
  margin-left: 15px;
  color: #828893;
  font-size: 15px;
  font-weight: 500;
}
.blog-details-heading ul li i {
  color: #582C85;
  margin-left: 2px;
}

.blog-details-content p {
  margin-top: 15px;
  font-size: 16px;
  line-height: 1.8;
}
.blog-details-content .blockquote {
  margin: 25px 0;
  background: #582C85;
  padding: 25px;
  color: #ffffff;
}
.blog-details-content .blockquote p {
  color: #ffffff;
  font-size: 18px;
  margin-top: 0;
  line-height: 1.6;
}
.blog-details-content .blockquote .blockquote-footer {
  color: #ffffff;
  margin-top: 15px;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
}
.blog-details-content .title {
  margin-bottom: 35px;
  font-size: 22px;
  font-weight: 700;
}

.blog-details-meta {
  margin: 25px 0;
  padding: 20px 0;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}
.blog-details-meta .tags {
  display: inline-block;
}
.blog-details-meta .tags li a {
  border: 1px solid #eeeeee;
  padding: 5px 14px;
  border-radius: 6px;
  display: inline-block;
  color: #6f8ba4;
  font-size: 15px;
  margin-right: 3px;
}
.blog-details-meta .tags li a:hover {
  background: #582C85;
  color: #ffffff;
  border-color: #582C85;
}
.blog-details-meta ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.blog-details-meta ul .title {
  color: #828893;
  font-size: 18px;
  font-weight: 500;
  margin-left: 5px;
  margin-bottom: 0;
}
.blog-details-meta ul li {
  display: inline-block;
}
.blog-details-meta .share {
  float: left;
}
.blog-details-meta .share li a {
  color: #6f8ba4;
  border: 1px solid #eeeeee;
  width: 35px;
  font-size: 14px;
  height: 35px;
  text-align: center;
  line-height: 34px;
  display: block;
  border-radius: 50%;
  margin-right: 5px;
}
.blog-details-meta .share li a:hover {
  background: #582C85;
  color: #ffffff;
  border-color: #582C85;
}

.blog-details-comments .single-comments {
  position: relative;
  padding-right: 130px;
}
.blog-details-comments .single-comments .thumb {
  position: absolute;
  right: 0;
  top: 0;
}
.blog-details-comments .single-comments .thumb img {
  border-radius: 50%;
}
.blog-details-comments .single-comments .content h4 {
  font-size: 18px;
  font-weight: 700;
}
.blog-details-comments .single-comments .content span {
  font-size: 14px;
  color: #6f8ba4;
  display: block;
  margin: 8px 0 -5px;
}
.blog-details-comments .single-comments .content p {
  margin-bottom: 15px;
}
.blog-details-comments .border {
  border-color: #eeeeee !important;
  margin: 25px 0;
}

.single-comments.left-mr {
  margin-right: 80px;
  margin-top: 35px;
}

.blog-details-comments-form {
  margin-top: 25px;
}

/*=============================
Page Title CSS
===============================*/
.page-title {
  z-index: 1;
  position: relative;
  overflow: hidden;
  background-image: url(../../assets/img/patt.png);
  text-align: center;
  padding-bottom: 100px;
  padding-top: 100px;
}
.page-title h3 {
  margin-bottom: 15px;
  font-size: 40px;
  font-weight: 700;
}
.page-title ul {
  padding: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.page-title ul li {
  display: inline-block;
  color: #283a5e;
  text-transform: capitalize;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 15px;
  font-weight: 600;
}
.page-title ul li.dot-divider {
  display: inline-block;
  height: 4px;
  width: 4px;
  background: #582C85;
  border-radius: 50%;
  margin: 0 5px;
  vertical-align: middle;
}
.page-title .bg-pattern {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
}

/*=============================
History CSS
===============================*/
.timeline {
  list-style-type: none;
  padding: 0;
  position: relative;
  margin-bottom: 0;
}
.timeline::before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 2px;
  background: #582C85;
  right: 50%;
  margin-right: -1.5px;
  border-radius: 5px;
}
.timeline > li {
  margin-bottom: 60px;
  position: relative;
  width: 48%;
  float: right;
  clear: right;
  text-align: left;
}
.timeline > li:nth-child(2) {
  margin-top: 110px;
}
.timeline > li:last-child {
  margin-bottom: 0;
  float: none;
}
.timeline > li > .timeline-panel {
  width: calc(100% - 25px);
  width: -moz-calc(100% - 25px);
  width: -webkit-calc(100% - 25px);
  float: right;
  position: relative;
  background: #ffffff;
  padding: 30px 20px;
  -webkit-box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.timeline > li > .timeline-panel:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.timeline > li > .timeline-panel::before {
  position: absolute;
  top: 26px;
  left: -15px;
  display: inline-block;
  content: " ";
  border-top: 15px solid transparent;
  border-right: 15px solid #ffffff;
  border-left: 0 solid #ffffff;
  border-bottom: 15px solid transparent;
}
.timeline > li > .timeline-badge {
  width: 24px;
  height: 24px;
  text-align: center;
  position: absolute;
  top: 29px;
  left: -34px;
  z-index: 100;
  background: #582C85;
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.timeline > li.timeline-inverted {
  float: left;
  clear: left;
  text-align: right;
}
.timeline > li.timeline-inverted > .timeline-badge {
  right: -35px;
}
.timeline > li.timeline-inverted > .timeline-panel {
  float: left;
}
.timeline > li.timeline-inverted > .timeline-panel::before {
  right: -15px;
  left: auto;
  border-right-width: 0;
  border-left-width: 15px;
}
.timeline > li.timeline-inverted > .timeline-panel::after {
  right: -14px;
  left: auto;
  border-right-width: 0;
  border-left-width: 14px;
}
.timeline > li.timeline-inverted .timeline-footer {
  text-align: right;
}
.timeline .timeline-title {
  margin-top: 0;
  color: inherit;
}
.timeline .timeline-heading h3 {
  margin-bottom: 12px;
  font-size: 22px;
  font-weight: 700;
}
.timeline .timeline-body > p {
  font-size: 16px;
  margin-bottom: 35px;
  margin-top: 15px;
}
.timeline .timeline-footer {
  position: absolute;
  background: #f4f6fd;
  width: 100%;
  right: 0;
  bottom: 0;
  height: auto;
  text-align: left;
  padding: 10px 15px;
}
.timeline .timeline-footer p {
  font-weight: 700;
  color: #582C85;
}

/*=============================
Sidebar CSS
===============================*/
.side-bar .widget-box {
  background: #ffffff;
  -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  padding: 30px 25px;
  position: relative;
  border-radius: 5px;
  margin-bottom: 30px;
}
.side-bar .widget-box .title {
  text-transform: capitalize;
  margin-bottom: 35px;
  position: relative;
  font-size: 22px;
  font-weight: 700;
}
.side-bar .widget-box .title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 17px;
  height: 17px;
  background: #582C85;
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.side-bar .widget-box ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.side-bar .search-box form {
  position: relative;
}
.side-bar .search-box button {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 50px;
  cursor: pointer;
  border: none;
  background: #582C85;
  color: #ffffff;
  outline: 0 !important;
}
.side-bar .categories-box ul li:last-child a {
  margin-bottom: 0;
}
.side-bar .categories-box ul li a {
  position: relative;
  margin-bottom: 10px;
  display: inline-block;
  font-weight: 600;
  padding-right: 18px;
}
.side-bar .categories-box ul li a i {
  position: absolute;
  right: 0;
  top: 4px;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.side-bar .tags-box .title {
  margin-bottom: 27px;
}
.side-bar .tags-box ul li {
  display: inline-block;
}
.side-bar .tags-box ul li a {
  border: 1px solid #ddd;
  padding: 5px 15px;
  margin-top: 8px;
  border-radius: 5px;
  font-weight: 600;
  display: inline-block;
  margin-left: 8px;
}
.side-bar .tags-box ul li a:hover {
  color: #ffffff;
  background: #582C85;
  border-color: #582C85;
}
.side-bar .recent-post-box ul li {
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
  padding-right: 110px;
}
.side-bar .recent-post-box ul li:last-child {
  margin-bottom: 0;
}
.side-bar .recent-post-box ul li .recent-post-thumb {
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
}
.side-bar .recent-post-box ul li .recent-post-desc h3 {
  line-height: 23px;
  font-size: 15px;
  font-weight: 700;
}
.side-bar .recent-post-box ul li .recent-post-desc .date {
  display: block;
  margin-top: 3px;
  font-size: 14px;
  color: #828893;
}

/*=============================
Pagination CSS
===============================*/
.pagination-area {
  text-align: center;
  margin-top: 40px;
}
.pagination-area .pagination {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.page-link {
  padding: 11px 20px;
  margin: 0 3px;
  color: #6f8ba4;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  -webkit-box-shadow: 0 2px 10px 0 #d8dde6;
  box-shadow: 0 2px 10px 0 #d8dde6;
}
.page-link:hover, .page-link:focus {
  color: #ffffff;
  background-color: #582C85;
}
.page-link:focus {
  box-shadow: unset;
}

.page-item.active .page-link {
  color: #ffffff;
  background-color: #582C85;
}

/*=============================
Subscribe CSS
===============================*/
.subscribe-area {
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-attachment: fixed;
  background-image: url(../../assets/img/bg-pattern-2.png);
}
.subscribe-area .pattern-1 {
  left: auto;
  right: 0;
  bottom: auto;
  top: 0;
}

.newsletter {
  max-width: 750px;
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  text-align: center;
  background: #ffffff;
  padding: 35px;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}
.newsletter h2 {
  text-transform: capitalize;
  margin-bottom: 30px;
  font-size: 40px;
  font-weight: 700;
}
.newsletter form {
  position: relative;
}
.newsletter form .form-control {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  height: 75px;
  border-radius: 50px;
  padding-right: 25px;
  font-size: 17px;
  background: #ffffff;
  border: none;
  max-width: unset;
}
.newsletter form .btn {
  position: absolute;
  left: 7px;
  top: 8px;
  height: 60px;
  width: 175px;
}

/*=============================
Error Page CSS
===============================*/
.error-area {
  text-align: center;
  background: rgba(255, 78, 0, 0.8);
}

.error {
  max-width: 550px;
  margin: 0 auto;
}
.error h1 {
  font-size: 268px;
  font-weight: 700;
  opacity: 0.8;
  color: #ffffff;
}
.error p {
  color: #ffffff;
  font-size: 25px;
  margin-top: 25px;
  margin-bottom: 30px;
}
.error form {
  position: relative;
}
.error .btn {
  position: absolute;
  left: 0;
  top: 0;
  color: #ffffff;
  border-radius: 0;
  background: #000;
  border-color: #000;
  height: 100%;
  width: 60px;
  padding: 0;
}

/*=============================
Contact CSS
===============================*/
.contact-box {
  background: #ffffff;
  padding: 25px 95px 25px 25px;
  border-radius: 5px;
  position: relative;
  -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.contact-box .icon {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #582C85;
  line-height: 47px;
  border: 1px dashed #582C85;
  border-radius: 50%;
  font-size: 22px;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.contact-box .content h3 {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 700;
}
.contact-box .content p {
  margin-bottom: 2px;
  font-size: 16px;
}
.contact-box .content p a {
  color: #777777;
  text-decoration: none;
  display: inline-block;
}
.contact-box .content p a:hover {
  color: #582C85;
}
.contact-box .content p:last-child {
  margin-bottom: 0;
}
.contact-box:hover {
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.contact-box:hover .icon {
  color: #ffffff;
  background: #582C85;
}

#map {
  margin-bottom: 40px;
  margin-top: 40px;
}
#map iframe {
  width: 100%;
  height: 400px;
  border: none;
  margin-bottom: -5px;
}

.contact-text h3 {
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 700;
}
.contact-text p {
  margin-top: 20px;
}
.contact-text .social-links {
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-top: 20px;
}
.contact-text .social-links li {
  display: inline-block;
  margin-left: 5px;
}
.contact-text .social-links li a {
  border: 1px solid #283a5e;
  width: 32px;
  height: 32px;
  text-align: center;
  display: inline-block;
  line-height: 31px;
  border-radius: 50%;
}
.contact-text .social-links li a:hover, .contact-text .social-links li a:focus {
  background: #582C85;
  color: #ffffff;
  border-color: #582C85;
}

#contactForm .btn {
  padding: 12px 40px;
  margin-top: 15px;
  font-size: 18px;
}

/*=============================
Repair Main Banner CSS
===============================*/
.repair-main-banner {
  position: relative;
  z-index: 1;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../../assets/img/slider-one.jpg);
  padding-top: 200px;
  padding-bottom: 280px;
}
.repair-main-banner::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #283a5e;
  z-index: -1;
  opacity: 0.45;
}

.repair-content-slides {
  max-width: 620px;
  margin-bottom: 10px;
}
.repair-content-slides.owl-theme .owl-dots {
  line-height: 0.1;
  text-align: right;
  margin-top: 70px !important;
  margin-right: 15px;
}
.repair-content-slides.owl-theme .owl-dots .owl-dot span {
  width: 20px;
  height: 3.5px;
  margin: 0 8px 0 0;
  border-radius: 0;
  background: #e2e1e1;
}
.repair-content-slides.owl-theme .owl-dots .owl-dot:hover span {
  background: #ffffff;
}
.repair-content-slides.owl-theme .owl-dots .owl-dot.active span {
  background: #ffffff;
  width: 30px;
}

.repair-banner-content h2 {
  color: #ffffff;
  line-height: 1.1;
  margin-bottom: 15px;
  font-size: 57px;
  font-weight: 700;
}
.repair-banner-content p {
  max-width: 550px;
  margin-bottom: 25px;
  color: #ffffff;
  font-size: 17px;
}

/*=============================
Repair Boxes Area CSS
===============================*/
.repair-boxes-area {
  background-color: transparent;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: -130px;
  margin-bottom: -30px;
}
.repair-boxes-area::before {
  content: "";
  position: absolute;
  right: 0;
  height: 100%;
  bottom: 0;
  width: 201%;
  background: #ffffff;
  z-index: -1;
  transform: skewY(-177deg);
}

.single-repair-box {
  text-align: center;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 70px 20px;
  margin-bottom: 30px;
}
.single-repair-box i {
  font-size: 45px;
  color: #582C85;
}
.single-repair-box h3 {
  font-size: 22px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 15px;
}
.single-repair-box .read-more-btn {
  margin-top: 20px;
}
.single-repair-box .left {
  position: absolute;
  right: 0;
  z-index: -1;
  top: 0;
}
.single-repair-box .right {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}

/*=============================
Repair About Area CSS
===============================*/
.repair-about-image {
  position: relative;
  padding-bottom: 180px;
}
.repair-about-image img:last-child {
  position: absolute;
  bottom: -5px;
  border: 5px solid #f8fbff;
  left: 0;
}

.repair-about-content .section-title {
  margin-bottom: 25px;
}
.repair-about-content .single-about-box {
  position: relative;
  margin-bottom: 25px;
  transition: 0.4s;
  background: #f8fbff;
  padding: 15px 60px 15px 15px;
}
.repair-about-content .single-about-box i {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 30px;
  color: #582C85;
}
.repair-about-content .single-about-box h3 {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 700;
}
.repair-about-content .single-about-box:hover {
  background-color: #ffffff;
  box-shadow: 0 10px 55px 5px rgba(137, 173, 255, 0.35);
  transform: translateY(-5px);
}
.repair-about-content .read-more-btn {
  margin-top: 20px;
}

/*=============================
Gallery Area CSS
===============================*/
.gallery-area {
  padding-bottom: 70px;
}
.gallery-area .section-title {
  margin-bottom: 40px;
}

.single-gallery-item {
  overflow: hidden;
  margin-bottom: 30px;
}
.single-gallery-item a {
  display: block;
}
.single-gallery-item a img {
  transition: all 1.5s ease-out;
}
.single-gallery-item:hover a img, .single-gallery-item:focus a img {
  transform: scale(1.4);
}

.gallery-shorting-menu {
  display: block;
  text-align: center;
  box-shadow: unset;
  padding: 0;
  background-color: transparent;
  border-radius: 0;
}
.gallery-shorting-menu .filter {
  border-radius: 0;
  background-color: transparent;
  border: 1px solid #eeeeee;
  color: #283a5e;
  font-weight: 700;
}
.gallery-shorting-menu .filter:hover, .gallery-shorting-menu .filter:focus, .gallery-shorting-menu .filter.mixitup-control-active {
  background-color: #582C85;
  color: #ffffff;
  border-color: #582C85;
}

/*=============================
Repair Services CSS
===============================*/
.repair-services-area {
  position: relative;
  z-index: 1;
  background-color: #002c60;
}
.repair-services-area .section-title h3 {
  color: #ffffff;
}
.repair-services-area .section-title p {
  color: #ffffff;
}
.repair-services-area::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  z-index: -1;
  height: 11%;
}

.single-repair-services {
  border: 1px solid #8096b0;
  padding: 30px;
  border-radius: 5px;
  transition: 0.4s;
}
.single-repair-services i {
  color: #ffffff;
  font-size: 40px;
  transition: 0.4s;
}
.single-repair-services h3 {
  transition: 0.4s;
  color: #ffffff;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 20px;
}
.single-repair-services p {
  color: #ffffff;
  opacity: 0.9;
  transition: 0.4s;
}
.single-repair-services .read-more-btn {
  margin-top: 15px;
}
.single-repair-services:hover, .single-repair-services:focus {
  background-color: #ffffff;
  border-color: #ffffff;
}
.single-repair-services:hover i, .single-repair-services:focus i {
  color: #582C85;
}
.single-repair-services:hover h3, .single-repair-services:focus h3 {
  color: #283a5e;
}
.single-repair-services:hover p, .single-repair-services:focus p {
  color: #828893;
}

.repair-services-slides.owl-theme .owl-dots {
  line-height: 0.1;
  margin-top: 45px !important;
}

.repair-services-inner {
  border-radius: 5px;
  margin-top: 80px;
  background-color: #582C85;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 25px;
  padding-right: 25px;
}
.repair-services-inner .single-inner-services {
  position: relative;
  padding-right: 50px;
}
.repair-services-inner .single-inner-services i {
  font-size: 35px;
  color: #ffffff;
  position: absolute;
  right: 0;
  top: 0;
}
.repair-services-inner .single-inner-services h3 {
  transition: 0.4s;
  color: #ffffff;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 20px;
}
.repair-services-inner .single-inner-services p {
  color: #ffffff;
  opacity: 0.9;
  transition: 0.4s;
}
.repair-services-inner .col-lg-4:nth-child(2) {
  border-left: 1px solid #d1613c;
  border-right: 1px solid #d1613c;
}

/*=============================
Repair Area CSS
===============================*/
.repair-partner-area {
  background-color: #002c60;
  padding-top: 50px;
  padding-bottom: 50px;
}

.single-repair-client {
  text-align: center;
}
.single-repair-client a {
  display: inline-block;
}
.single-repair-client a img {
  width: auto !important;
  display: inline-block !important;
}

/*=============================
Repair Feedback CSS
===============================*/
.single-repair-feedback {
  border: 1px solid #eeeeee;
  padding: 30px;
  border-radius: 5px;
}
.single-repair-feedback .client-info {
  margin-bottom: 25px;
  position: relative;
  padding-right: 90px;
  padding-top: 18px;
}
.single-repair-feedback .client-info img {
  display: inline-block !important;
  width: 70px !important;
  height: 70px;
  position: absolute;
  right: 0;
  top: 0;
}
.single-repair-feedback .client-info h3 {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 700;
}
.single-repair-feedback .client-info span {
  display: block;
  color: #002c60;
}
.single-repair-feedback ul {
  padding-right: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 15px;
}
.single-repair-feedback ul li {
  display: inline-block;
  color: #ff5202;
  margin-left: 2px;
}

.repair-feedback-slides.owl-theme .owl-dots {
  line-height: 0.1;
  margin-top: 45px !important;
}

/*=============================
Repair Blog CSS
===============================*/
.single-repair-blog-post .blog-image {
  overflow: hidden;
  position: relative;
}
.single-repair-blog-post .blog-image a {
  display: block;
}
.single-repair-blog-post .blog-image a img {
  transition: all 1.5s ease-out;
}
.single-repair-blog-post .blog-image .tags {
  position: absolute;
  right: 15px;
  z-index: 2;
  top: 15px;
  transition: 0.4s;
}
.single-repair-blog-post .blog-image .tags a {
  background: #582C85;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 5px;
  display: inline-block;
  padding: 5px 10px 3px;
  font-weight: 600;
  font-size: 13px;
}
.single-repair-blog-post .blog-content {
  margin-top: 20px;
}
.single-repair-blog-post .blog-content h3 {
  line-height: 1.3;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 700;
}
.single-repair-blog-post .blog-content ul {
  list-style-type: none;
  margin-top: 15px;
  margin-bottom: 0;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-right: 0;
}
.single-repair-blog-post .blog-content ul li {
  display: inline-block;
  color: #828893;
  font-weight: 600;
  position: relative;
  margin-left: 20px;
}
.single-repair-blog-post .blog-content ul li::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 7px;
  height: 2px;
  background: #582C85;
  right: -13px;
}
.single-repair-blog-post .blog-content ul li a {
  display: inline-block;
  color: #828893;
}
.single-repair-blog-post .blog-content ul li a:hover, .single-repair-blog-post .blog-content ul li a:focus {
  color: #582C85;
}
.single-repair-blog-post .blog-content ul li:first-child::before {
  display: none;
}
.single-repair-blog-post .blog-content ul li:last-child {
  float: left;
  margin-left: 0;
}
.single-repair-blog-post .blog-content ul li:last-child::before {
  display: none;
}
.single-repair-blog-post .blog-content ul li:last-child a {
  color: #582C85;
}
.single-repair-blog-post:hover .blog-image a img, .single-repair-blog-post:focus .blog-image a img {
  transform: scale(1.3);
}

/*=============================
Repair Subscribe Area CSS
===============================*/
.repair-subscribe-area {
  position: relative;
  margin-top: -100px;
  margin-bottom: 100px;
}

.repair-subscribe-content {
  background-color: #582C85;
  text-align: center;
  border-radius: 5px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 30px;
  padding-right: 30px;
}
.repair-subscribe-content h2 {
  font-size: 28px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 30px;
}
.repair-subscribe-content form {
  overflow: hidden;
}
.repair-subscribe-content form .form-group {
  float: left;
  margin-bottom: 0;
  width: 29%;
  padding-right: 15px;
}
.repair-subscribe-content form .form-group .input-newsletter {
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #ffffff;
  background: transparent;
  outline: 0;
  color: #ffffff;
  padding-bottom: 10px;
  padding-top: 12px;
}
.repair-subscribe-content form .form-group .input-newsletter::placeholder {
  color: #ffffff;
}
.repair-subscribe-content form .validation-danger, .repair-subscribe-content form .validation-success {
  color: #ffffff;
}
.repair-subscribe-content form .btn {
  float: left;
  width: 13%;
}
.repair-subscribe-content form .btn-primary {
  color: #ffffff;
  background-color: #002c60;
}
.repair-subscribe-content form .btn-primary.disabled, .repair-subscribe-content form .btn-primary:disabled {
  color: #ffffff;
  background-color: #002c60;
}
.repair-subscribe-content form .btn-primary:hover, .repair-subscribe-content form .btn-primary:focus {
  color: #283a5e;
  background-color: #ffffff;
}

/*=============================
Footer CSS
===============================*/
.footer-area {
  padding-top: 100px;
  position: relative;
  z-index: 1;
}
.footer-area::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: url(../../assets/img/map2.png);
  opacity: 0.2;
  background-attachment: fixed;
}
.footer-area.repair-footer-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: transparent;
}
.footer-area.repair-footer-area::after {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
  width: 201%;
  background: #002c60;
  z-index: -1;
  transform: skewY(177deg);
}
.footer-area.repair-footer-area::before {
  display: none;
}
.footer-area.repair-footer-area .copyright-area {
  background-color: #000000;
}
.footer-area.repair-footer-area .single-footer-widget .logo a {
  color: #ffffff;
}
.footer-area.repair-footer-area .single-footer-widget p {
  color: #f2f2f2;
}
.footer-area.repair-footer-area .single-footer-widget h3 {
  color: #ffffff;
}
.footer-area.repair-footer-area .single-footer-widget .services-list li a {
  color: #ffffff;
}
.footer-area.repair-footer-area .single-footer-widget .services-list li a:hover, .footer-area.repair-footer-area .single-footer-widget .services-list li a:focus {
  color: #582C85;
}
.footer-area.repair-footer-area .single-footer-widget .usefull-links li a {
  color: #ffffff;
}
.footer-area.repair-footer-area .single-footer-widget .usefull-links li a:hover, .footer-area.repair-footer-area .single-footer-widget .usefull-links li a:focus {
  color: #582C85;
}
.footer-area.repair-footer-area .single-footer-widget .contact-list li {
  color: #ffffff;
}
.footer-area.repair-footer-area .single-footer-widget .contact-list li a {
  color: #ffffff;
}
.footer-area.repair-footer-area .single-footer-widget .contact-list li a:hover, .footer-area.repair-footer-area .single-footer-widget .contact-list li a:focus {
  color: #582C85;
}
.footer-area.repair-footer-area .social-links li a {
  border: none;
  color: #ffffff;
  background-color: #343434;
  line-height: 35px;
  font-size: 14px;
}
.footer-area.repair-footer-area .social-links li a:hover, .footer-area.repair-footer-area .social-links li a:focus {
  background-color: #582C85;
  color: #ffffff;
}

.single-footer-widget {
  margin-bottom: 30px;
}
.single-footer-widget .logo a {
  display: inline-block;
}
.single-footer-widget p {
  color: #283a5e;
  margin-top: 15px;
  margin-bottom: 20px;
}
.single-footer-widget h3 {
  text-transform: uppercase;
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: 700;
}
.single-footer-widget .services-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.single-footer-widget .services-list li a {
  margin-bottom: 12px;
  font-weight: 600;
  display: inline-block;
}
.single-footer-widget .services-list li a:hover {
  padding-right: 10px;
}
.single-footer-widget .services-list li:last-child a {
  margin-bottom: 0;
}
.single-footer-widget .usefull-links {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.single-footer-widget .usefull-links li a {
  margin-bottom: 12px;
  font-weight: 600;
  display: inline-block;
}
.single-footer-widget .usefull-links li a:hover {
  padding-right: 10px;
}
.single-footer-widget .usefull-links li:last-child a {
  margin-bottom: 0;
}
.single-footer-widget .contact-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.single-footer-widget .contact-list li {
  margin-bottom: 12px;
  font-weight: 500;
  color: #283a5e;
  position: relative;
}
.single-footer-widget .contact-list li a {
  display: inline-block;
}
.single-footer-widget .contact-list li:last-child {
  margin-bottom: 0;
}
.single-footer-widget .contact-list li span {
  font-weight: 700;
  display: inline-block;
  margin-left: 2px;
}
.single-footer-widget .working-hours li {
  margin-bottom: 12px;
  font-weight: 500;
  color: #ffffff;
  position: relative;
}
.single-footer-widget .working-hours li:last-child {
  margin-bottom: 0;
}
.single-footer-widget .working-hours li a {
  display: inline-block;
}
.single-footer-widget .working-hours li span {
  display: inline-block;
  font-weight: 700;
}
.single-footer-widget .social-links {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.single-footer-widget .social-links li {
  display: inline-block;
  margin-left: 5px;
}
.single-footer-widget .social-links li a {
  border: 1px solid #283a5e;
  width: 32px;
  height: 32px;
  text-align: center;
  display: inline-block;
  line-height: 31px;
  border-radius: 50%;
}
.single-footer-widget .social-links li a:hover, .single-footer-widget .social-links li a:focus {
  background: #582C85;
  color: #ffffff;
  border-color: #582C85;
}

.copyright-area {
  background: #582C85;
  margin-top: 70px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.copyright-area p {
  color: #ffffff;
}
.copyright-area p a {
  display: inline-block;
  color: #ffffff;
  font-weight: 700;
}
.copyright-area p a:hover {
  color: #582C85;
}
.copyright-area ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}
.copyright-area ul li {
  display: inline-block;
  position: relative;
  padding-left: 20px;
}
.copyright-area ul li::before {
  content: "";
  position: absolute;
  right: -12px;
  top: 4px;
  background: #6f8ba4;
  width: 2px;
  height: 14px;
}
.copyright-area ul li:last-child {
  padding-left: 0;
}
.copyright-area ul li:first-child::before {
  display: none;
}
.copyright-area ul li a {
  display: inline-block;
  color: #ffffff;
}
.copyright-area ul li a:hover {
  color: #582C85;
}

/*=============================
Left Side Modal CSS
===============================*/
.sidebarModal.modal.left .modal-dialog {
  position: fixed;
  margin: auto;
  width: 420px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}
.sidebarModal.modal.left .modal-content {
  height: 100%;
  overflow-y: auto;
}
.sidebarModal.modal.left .modal-body {
  padding: 20px;
}
.sidebarModal.modal.left .modal-body p {
  margin-top: 20px;
}
.sidebarModal.modal.left .modal-body ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.sidebarModal.modal.left .modal-body .modal-contact-info {
  margin-top: 30px;
  margin-bottom: 20px;
}
.sidebarModal.modal.left .modal-body .modal-contact-info li {
  position: relative;
  padding-right: 45px;
  font-size: 18px;
  margin-bottom: 25px;
}
.sidebarModal.modal.left .modal-body .modal-contact-info li strong {
  display: block;
}
.sidebarModal.modal.left .modal-body .modal-contact-info li i {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 35px;
  color: #582C85;
}
.sidebarModal.modal.left .modal-body .social-links li {
  display: inline-block;
  margin-left: 5px;
}
.sidebarModal.modal.left .modal-body .social-links li a {
  border: 1px solid #283a5e;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  display: block;
  font-size: 14.5px;
}
.sidebarModal.modal.left .modal-body .social-links li a:hover, .sidebarModal.modal.left .modal-body .social-links li a:focus {
  background: #582C85;
  color: #ffffff;
  border-color: #582C85;
}
.sidebarModal.modal.left .modal-body .social-links li:last-child {
  margin-left: 0;
}
.sidebarModal.modal.left .modal-body .logo a {
  display: inline-block;
}
.sidebarModal.modal.left.fade .modal-dialog {
  left: -420px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}
.sidebarModal.modal.left.fade.show .modal-dialog {
  left: 0;
}
.sidebarModal .modal-content {
  border-radius: 0;
  border: none;
}
.sidebarModal .modal-header {
  border-bottom-color: transparent;
  background-color: transparent;
  padding: 20px 30px 0;
}
.sidebarModal .modal-header .close {
  color: #283a5e;
  font-size: 35px;
  outline: 0 !important;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  padding: 0;
  border: 0;
  background-color: transparent;
}
.sidebarModal .modal-header .close:not(:disabled):not(.disabled):hover, .sidebarModal .modal-header .close:not(:disabled):not(.disabled):focus {
  color: #582C85;
  text-decoration: none;
  opacity: 1;
}

/*=============================
Go Top CSS
===============================*/
.go-top {
  position: fixed;
  cursor: pointer;
  bottom: 30px;
  left: 0;
  font-size: 25px;
  color: #ffffff;
  background-color: #283a5e;
  z-index: 1;
  display: none;
  width: 45px;
  text-align: center;
  height: 45px;
  border-radius: 0 10px 10px 0;
  line-height: 46px;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.go-top:hover {
  color: #ffffff;
  background: #582C85;
}

input {
  text-align: right;
}