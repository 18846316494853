/*
@File: Agcia - Angular 10 SEO & IT Company Template

* This file contains the styling for the actual tepmlate, this
is the file you need to edit to change the look of the
tepmlate.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

==== INDEX PAGE CSS

** - Default CSS
** - Preloader CSS
** - Header CSS
** - Navbar CSS
** - Main Banner CSS
** - Boxes Area CSS
** - About CSS
** - Features CSS
** - Services CSS
** - Analysis CSS
** - Project CSS
** - Project Details CSS
** - Working Process CSS
** - Team CSS
** - Testimonials CSS
** - FunFacts CSS
** - Check Your Website CSS
** - Pricing CSS
** - Partner CSS
** - Partner CSS
** - Blog CSS
** - Blog Details CSS
** - Page Title CSS
** - History CSS
** - Sidebar CSS
** - Pagination CSS
** - Subscribe CSS
** - FAQ CSS
** - Error Page CSS
** - Contact CSS
** - Footer CSS
** - Right Side Modal CSS
** - Go Top CSS

*/

/*=============================
Default CSS
===============================*/
$font_family: 'Tajawal', sans-serif;
$template_color: #582C85;
$template_second:#00a09d;
$white_color: #ffffff;
$black_color: #283a5e;
$paragraph_color: #828893;
$black_blue_color: #002c60;
$font_size: 15px;
$transition: .4s;

$itajer_primary: #29c0b1;
$itajer_secondary: #36595b;

.itajer-primary {
    color: $template_second;
}
.itajer-secondary {
    color: $itajer_secondary;
}

.sb-chat .sb-popup-message {

    bottom: 88px !important;

}

.btn_itajer-primary {
	background-color: $template_second;
    color: $white_color;
    box-shadow: 0px 15px 25px 0px rgba(53,90,91,0.3);
    &:hover, &:focus{
        color: $template_second;
        background-color: $white_color;
        border-color: $white_color;
        box-shadow: 0px 15px 25px 0px rgba(53,90,91,0.3);
    }
    &.focus, &:focus {
        box-shadow: 0px 15px 25px 0px rgba(53,90,91,0.3);
    }
    &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
        color: $itajer_primary;
        background-color: $white_color;
        border-color: $white_color;

        &:focus{
            box-shadow: 0px 15px 25px 0px rgba(53,90,91,0.3);
        }
    }
    &.disabled, &:disabled {
        color: $white_color;
        background-color: $itajer_primary;
        border-color: #007bff;
        opacity: 1;
    }
    &.disabled:hover, &:disabled{
        color: $itajer_primary;
	    background-color: $white_color;
    }
}

.particles-js-canvas-el{
    z-index: -1;
}
section{
background: #fff;
}


body {
    padding: 0;
    margin: 0;
    font: {
        size: $font_size;
        family: $font_family;
    };
}
a {
	outline: 0 !important;
	color: $black_color;
	text-decoration: none;
	-ms-transition: $transition;
	-o-transition: $transition;
	-moz-transition: $transition;
	-webkit-transition: $transition;
	transition: $transition;

    &:hover, &:focus {
        color: $template_color;
	    text-decoration: none;
    }
}
img {
    max-width: 100%;
    height: auto;
}
:focus {
    outline: 0 !important;
}
.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	margin-bottom: 0;
	line-height: 1;
	color: $template_second;
}
p {
	color: $paragraph_color;
	font-size: 16px;
	line-height: 1.6;
    margin: {
        bottom: 0;
        top: 0;
    };
}
.bg-fbf9f8 {
    background: #fbf9f8;
}
.bg-fbf9f8 {
    background-color: #fbf9f8;
}
.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    };
}
.mt-30 {
    margin-top: 30px;
}
/*btn*/
.btn {
	border-radius: 100px;
	box-shadow: 0px 15px 25px 0px rgba(88,44,133,0.3);
	padding: 14px 40px;
    border: none;
    display: inline-block;
    text-transform: uppercase;
    font: {
        size: 15px;
        weight: 700;
    };
    
}
.btn-primary {
	background-color: $template_color;
    color: $white_color;

    &:hover, &:focus{
        color: $template_color;
        background-color: $white_color;
        border-color: $white_color;
    }
    &.focus, &:focus {
        box-shadow: 0px 15px 25px 0px rgba(88,44,133,0.3);
    }
    &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
        color: $template_color;
        background-color: $white_color;
        border-color: $white_color;

        &:focus{
            box-shadow: 0px 15px 25px 0px rgba(88,44,133,0.3);
        }
    }
    &.disabled, &:disabled {
        color: $white_color;
        background-color: $template_color;
        border-color: #007bff;
        opacity: 1;
    }
    &.disabled:hover, &:disabled{
        color: $template_color;
	    background-color: $white_color;
    }
}
/*Section Title*/
.section-title {
    margin-bottom: 60px;
    position: relative;

    span {
        color: $template_color;
        text-transform: capitalize;
        font: {
            size: 16px;
            weight: 400;
        };
    }
    h3 {
        text-transform: capitalize;
        max-width: 635px;
        line-height: 44px;
        font: {
            size: 40px;
            weight: 700;
        };
        margin: {
            bottom: 17px;
            top: 13px;
        };
    }
    p {
        max-width: 635px;
    }
    .read-more-btn {
        position: absolute;
        left: 0;
        display: inline-block;
        font-size: 16px;
        top: 50%;
        transform: translateY(-50%);
        text: {
            decoration: underline;
        };
    }
    &.text-center {
        h3 {
            margin: {
                left: auto;
                right: auto;
            };
        }
        p {
            margin: {
                left: auto;
                right: auto;
            };
        }
    }
}
/*Form Control*/
.form-group {
	margin-bottom: 15px;
}
.form-control {
	height: 50px;
	font-size: 16px;
	padding: 10px 20px;
	border: 1px solid #f3f5fe;
	outline: 0;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	-moz-transition: all .3s ease;
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
    background: #f3f5fe;

    &::placeholder {
        color: #6e6e6e !important;
    }
    &:focus {
        color: #6e6e6e;
        background-color: $white_color;
        outline: 0;
        box-shadow: unset;
        border-color: $template_color;
    }
}
/*Owl Carousel btn*/
.owl-theme {
    .owl-nav {
        margin-top: 0;

        [class*="owl-"] {
            color: $white_color !important;
            font-size: 20px !important;
            transform: translateY(-50%);
            margin: 0;
            padding: 0 !important;
            background: #D6D6D6 !important;
            border-radius: 4px;
            position: absolute;
            top: 50%;
            right: 40px;
            width: 40px;
            height: 40px;
            line-height: 42px !important;
            -moz-transition: $transition;
            -webkit-transition: $transition;
            transition: $transition;

            &:hover {
                background: $template_color !important;
                color: $white_color !important;
            }
        }
        .owl-next {
            right: auto;
            left: 40px;
        }
    }
    .owl-dots {
        line-height: .1;
        margin-top: 20px;

        .owl-dot {
            span {
                width: 15px;
                height: 15px;
                margin: 0 4px;
                -moz-transition: $transition;
                -webkit-transition: $transition;
                transition: $transition;
                border-radius: 30px;
            }
            &.active span, &:hover span {
                background: $template_color;
            }
        }
    }
}

/*=============================
Preloader CSS
===============================*/
.preloader-area {
	position: fixed;
	width: 100%;
	height: 100%;
	background: $white_color;
	z-index: 999999;
	left: 0;
    top: 0;
    
    .spinner {
        margin: -85px auto 0 -65px;
        width: 65px;
        height: 65px;
        position: relative;
        text-align: center;
        -webkit-animation: sk-rotate 2.0s infinite linear;
        animation: sk-rotate 2.0s infinite linear;
        position: absolute;
        left: 50%;
        top: 50%;
    }
    .dot1, .dot2 {
        width: 60%;
        height: 60%;
        display: inline-block;
        position: absolute;
        top: 0;
        background-color: $template_color;
        border-radius: 100%;
        -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
        animation: sk-bounce 2.0s infinite ease-in-out;
    }
    .dot2 {
        top: auto;
        bottom: 0;
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s;
    }
}
@-webkit-keyframes sk-rotate {
    100% { 
        -webkit-transform: rotate(360deg) 
    }
}
@keyframes sk-rotate { 
    100% { 
        transform: rotate(360deg); 
        -webkit-transform: rotate(360deg) 
    }
}
@-webkit-keyframes sk-bounce {
    0%, 100% { 
        -webkit-transform: scale(0.0) 
    }
    50% { 
        -webkit-transform: scale(1.0) 
    }
}
@keyframes sk-bounce {
    0%, 100% { 
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    } 50% { 
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}
/*=============================
Top Header CSS
===============================*/
.top-header-area {
	background: $template_color;
    padding: {
        top: 12px;
        bottom: 12px;
    };
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            display: inline-block;
        }
    }
    .social-links {
        li {
            a {
                color: $white_color;
                margin-right: 8px;
                border: none;
                width: auto;
                display: inline-block;
                height: auto;
                line-height: unset;
                border-radius: unset;

                &:hover {
                    -webkit-transform: translateY(-2px);
                    transform: translateY(-2px);
                }
            }
            &:first-child {
                color: $white_color;
                font: {
                    weight: 700;
                };
            }
        }
    }
    .header-info {
        text-align: left;

        li {
            color: $white_color;
            position: relative;
            font: {
                weight: 600;
            };
            padding: {
                right: 15px;
                left: 6px;
            };
            &::before {
                content: "";
                position: absolute;
                right: 3px;
                top: 4px;
                height: 14px;
                width: 2px;
                background-color: $white_color;
                opacity: 0.3;
            }
            &:first-child {
                padding-right: 0;

                &::before {
                    display: none;
                }
            }
            &:last-child {
                padding-left: 0;
            }
            a {
                color: $white_color;
                font: {
                    weight: 600;
                };
            }
        }
    }
}

/*=============================
Navbar CSS
===============================*/
.navbar {
    padding: {
        left: 0;
        right: 0;
        top: 15px;
        bottom: 15px;
    };
    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        padding: 15px 0;
        background-color: $white_color !important;
        -webkit-box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
        -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    }
    .navbar-brand {
        margin-right: 0;
        margin-left: 1rem;
    }
}
.bg-light {
    background-color: transparent !important;
}
.navbar-light {
    .navbar-nav {
        padding-right: 0;
        margin-right: 50px;

        .nav-item {
            position: relative;
            margin: {
                left: 13px;
                right: 13px;
            };
            .nav-link {
                color: $black_color;
                position: relative;
                padding: {
                    top: 15px;
                    bottom: 15px;
                    right: 0;
                    left: 0;
                };
                font: {
                    size: 17px;
                    weight: 600;
                };
                &:hover, &.active {
                    color: $template_color;
                }
                i {
                    position: relative;
                    top: 1px;
                    display: inline-block;
                    margin-right: -3px;
                }
            }
            .dropdown-menu {
                box-shadow: 0 0 15px 0 rgba(0,0,0,.1);
                background: $white_color;
                position: absolute;
                border: none;
                top: 100%;
                left: auto;
                right: 0;
                width: 250px;
                z-index: 99;
                display: block;
                opacity: 0;
                visibility: hidden;
                border-radius: 0;
                transition: all .3s ease-in-out;
                margin-top: 20px;
                text-align: right;
                padding: {
                    top: 13px;
                    left: 20px;
                    right: 20px;
                    bottom: 13px;
                };
                .nav-item {
                    margin: {
                        left: 0;
                        right: 0;
                    };
                    .nav-link {
                        font-weight: 500;
                        color: $black_color;
                        padding: {
                            top: 7px;
                            bottom: 7px;
                        };
                        &:hover, &.active {
                            color: $template_color;
                        }
                    }
                    .dropdown-menu {
                        opacity: 0;
                        visibility: hidden;
                        top: 0;
                        margin-top: 15px;
                        right: 230px;

                        .nav-item {
                            .dropdown-menu {
                                opacity: 0;
                                visibility: hidden;
                                top: 0;
                                margin-top: 15px;
                                right: -230px;
                            }
                            &:hover {
                                .dropdown-menu {
                                    opacity: 1;
                                    visibility: visible;
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                    &:hover {
                        .dropdown-menu {
                            opacity: 1;
                            visibility: visible;
                            margin-top: 0;
                        }
                    }
                }
            }
            &:last-child {
                margin-left: 0;
            }
            &:first-child {
                margin-right: 0;
            }
            &:hover {
                .dropdown-menu {
                    opacity: 1;
                    visibility: visible;
                    margin-top: 0;
                }
            }
        }
    }
    .others-option {
        margin-right: auto;
        display: flex;
        align-items: center;

        .lang-switcher {
            select {
                border: 1px solid #eeeeee;
                padding: 2px;
                background: transparent;
                cursor: pointer;
            }
        }
        .call-us {
            margin-right: 15px;

            span {
                display: inline-block;
                color: $paragraph_color;
                font: {
                    size: 16px;
                    weight: 500;
                };
            }
            a {
                display: inline-block;
                color: $template_color;
                margin-right: 1px;
                position: relative;
                top: .5px;
                font: {
                    size: 16px;
                    weight: 500;
                };
                &:hover {
                    color: $black_color;
                }
            }
        }
        .modal-taggle-button {
            margin-right: 15px;

            a {
                display: block;
                width: 35px;
                position: relative;
                height: 18px;

                &::before {
                    content: '';
                    width: 30px;
                    height: 2px;
                    background: $template_color;
                    position: absolute;
                    left: 0;
                    -webkit-transition: $transition;
                    transition: $transition;
                    bottom: 8px;
                }
                span {
                    display: block;

                    &::before, &::after {
                        content: '';
                        width: 35px;
                        height: 2px;
                        background: $template_color;
                        position: absolute;
                        left: 0;
                        -webkit-transition: $transition;
                        transition: $transition;
                    }
                    &::after {
                        bottom: 0;
                    }
                    &::before {
                        top: 0;
                    }
                }
                &:hover {
                    &::before {
                        width: 35px;
                    }
                }
            }
        }
    }
}
.navbar-light {
    .navbar-toggler {
        border-color: $black_color;
        color: $black_color;
        border-radius: 0;

        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        }
    }
}
// Mobile and iPad Navbar
@media only screen and (max-width: 991px) {

    .navbar {
        .container {
            padding: {
                left: 15px;
                right: 15px;
            };
        }
    }
    .navbar-light {
        .navbar-nav {
            background-color: $white-color;
            border: 1px solid #eeeeee;
            overflow-y: scroll;
            height: 350px;
            padding: 10px 20px;
            margin: {
                top: 15px;
                right: 0;
            };
            .nav-item {
                .nav-link {
                    font-size: 15px;
                    padding: {
                        top: 10px;
                        bottom: 10px;
                    };
                    margin: {
                        left: 0;
                        right: 0;
                    };
                }
                .dropdown-menu {
                    box-shadow: unset;
                    position: relative;
                    top: 0;
                    left: 0;
                    width: 100%;
                    right: 0;
                    opacity: 1;
                    visibility: visible;
                    margin-top: 0;
                    border: 1px solid #eeeeee;
                    padding: {
                        top: 5px;
                        left: 10px;
                        right: 10px;
                        bottom: 5px;
                    };
                    .nav-item {
                        .nav-link {
                            padding: 5px 10px;
                            font-size: 15px;

                            &.active, &:hover, &:focus {
                                padding-right: 10px;
                            }
                        }
                        .dropdown-menu {
                            right: 10px;
                            top: 0 !important;
                            opacity: 1;
                            visibility: visible;
                            margin-top: 2px;
                            border-left: none;
                        }
                        &:hover {
                            .dropdown-menu {
                                top: 0;
                            }
                        }
                    }
                }
                &:hover {
                    .dropdown-menu {
                        top: 0;
                    }
                }
            }
        }
        .others-option {
            display: none;
        }
    }

}

/*=============================
Main Banner CSS
===============================*/
.main-banner {
	position: relative;
	height: 730px;
	overflow: hidden;
    background: {
        position: center;
        size: cover;
        repeat: no-repeat;
    };
}
.hero-bg-1 {
	background-image: url(../../assets/img/bg-pattern-2.png);
}
.circle-pattern-1 {
	-webkit-clip-path: circle(50% at 60% 46%);
	clip-path: circle(50% at 60% 46%);
	background: #fffaf8;
    position: absolute;
	left: 0;
	top: 0;
	width: 50%;
	height: 100%;
	z-index: -1;
    -webkit-animation: movebounce 3s linear infinite;
    animation: movebounce 3s linear infinite;
}
.hero-content {
    h1 {
        line-height: 1.1;
        font: {
            size: 45px;
            weight: 700;
        };
        span {
            color: $template_color;
            position: relative;
            z-index: 1;

            &::after {
                content: '';
                position: absolute;
                bottom: 12px;
                left: 0;
                width: 100%;
                height: 0px;
                z-index: -1;
                background: $black_color;
            }
        }
    }
    p {
        font-size: 19px;
        margin: {
            bottom: 35px;
            top: 25px;
        };
    }
    .btn {
        margin-right: 25px;
    }
    .video-btn {
        position: relative;
        padding-right: 48px;
        display: inline-block;
        text-transform: uppercase;
        font-weight: 900;
    
        i {
            margin-left: 12px;
            font-size: 35px;
            position: absolute;
            right: 0;
            bottom: -6px;
        }
    }
}
.hero-image {
	position: absolute;
	left: -100px;
	bottom: 30px;
	width: 725px;
}
.main-banner-two {
	height: 750px;
	overflow: hidden;
    position: relative;
    z-index: 1;
}
.main-banner-three {
	height: 630px;
	overflow: hidden;
	position: relative;
    background-image: url(../../assets/img/bg-pattern-2.png);

    &.hero-bg-1 {
        background-image: url(../../assets/img/bg-pattern-2.png);
    }
}
.main-banner-four {
	height: 630px;
	position: relative;
	background-position: center;
	background-size: cover;
    z-index: 1;
    background-repeat: no-repeat;

    &::before {
        content: '';
        z-index: -1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $white_color;
        opacity: .9;
    }
    .hero-content {
        margin-top: 30px;
        
        h1 {
            text-transform: capitalize;
        }
    }
}
.item-bg-1 {
    background-image: url(../../assets/img/bg2.png);
}
.item-bg-2 {
    background-image: url(../../assets/img/bg2.png);
}
.item-bg-3 {
    background-image: url(../../assets/img/bg2.png);
}
::-webkit-input-placeholder { text-align:right; }
/* mozilla solution */
input:-moz-placeholder { text-align:right; }

/*=============================
Boxes CSS
===============================*/
.box {
	background: $template_color;
	padding: 0 30px;
	height: 110px;
    position: relative;
    overflow: hidden;
	z-index: 1;
    cursor: pointer;

    h3 {
        color: $white_color;
        margin-bottom: 0;
        font: {
            size: 22px;
            weight: 700;
        };
    }
    span {
        font-size: 100px;
        color: rgba(255, 255, 255, 0.1);
        position: absolute;
        right: 20px;
        top: 10px;
        z-index: -1;
    }
    &.bg {
        background: #e6541b;
    }
}
.boxes-area {
    .owl-thumbs{
        overflow: hidden;
    }
    .owl-thumb-item {
        float: left;
        width: 33.333%;
    }
}

/*=============================
About CSS
===============================*/
.about-area {
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: {
        image: url(../../assets/img/bg-pattern.png);
        attachment: fixed;
    };
}
.single-box {
	text-align: center;
	background: $white_color;
	padding: 25px;
	border-radius: 5px;
	-webkit-box-shadow: 7px 5px 30px rgba(72,73,121,0.15);
	box-shadow: 7px 5px 30px rgba(72,73,121,0.15);
	-webkit-transition: $transition;
	-moz-transition: $transition;
    transition: $transition;

    &:hover {
        background: $template_color;
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);

        p, h3 {
            color: $white_color;
        }
        .read-more-btn {
            color: $white_color;

            &::before {
                background: $white_color;
            }
        }
    }
    .icon {
        background: #f4f6fd;
        display: inline-block;
        width: 100px;
        height: 100px;
        font-size: 40px;
        color: $template_color;
        line-height: 100px;
        -webkit-clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
        clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
        -webkit-transition: $transition;
        -moz-transition: $transition;
        transition: $transition;
    }
    h3 {
        font: {
            size: 22px;
            weight: 700;
        };
        margin: {
            bottom: 15px;
            top: 30px;
        };
    }
    p {
        margin-bottom: 20px;
    }
    p, h3 {
        -webkit-transition: $transition;
        -moz-transition: $transition;
        transition: $transition;
    }
}
.read-more-btn {
	text-transform: uppercase;
	font-weight: 700;
	position: relative;
	color: $template_color;
    z-index: 1;
    display: inline-block;
    
    &::before {
        content: '';
        position: absolute;
        right: 50px;
        bottom: 11px;
        width: 55px;
        height: 2px;
        background: $template_color;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: $transition;
        -moz-transition: $transition;
        transition: $transition;
        z-index: -1;
    }
    &:hover, &:focus {
        color: $black_color;

        &::before {
            right: -20px;
            opacity: 1;
            visibility: visible;
        };
    }
}
.about-inner-area {
    margin-top: 80px;
}
.about-image {
    position: relative;
    z-index: 1;

    .col-lg-6 {
        &:first-child {
            .image img {
                border-radius: 0 25px 0 0;
            }
        }
        &:nth-child(2) {
            .image img {
                border-radius: 25px 0 0 0;
            }
        }
        &:nth-child(3) {
            .image img {
                border-radius: 0 0 25px 0;
            }
        }
        &:last-child {
            .image img {
                border-radius: 0 0 0 25px;
            }
        }
    }
    .image {
        img {
            box-shadow: 0px 10px 20px 0px rgba(130,136,147,0.15);
            -webkit-transition: .2s;
            -moz-transition: .2s;
            transition: .2s;
        }
        &:hover {
            img {
                -webkit-animation: movebounce 3s linear infinite;
                animation: movebounce 2s linear infinite;
            }
        }
    }
}
.about-text {
    padding-right: 30px;

    span {
        color: $template_color;
        text-transform: capitalize;
        font: {
            size: 16px;
            weight: 400;
        };
    }
    h3 {
        margin: 15px 0 20px;
        line-height: 40px;
        font: {
            size: 40px;
            weight: 700;
        };
    }
    p {
        margin-bottom: 20px;
    }
    .read-more-btn {
        margin-top: 10px;
    }
}
.circle-pattern-2 {
	height: 39em;
	position: absolute;
	width: 42em;
	bottom: -10px;
	right: -10px;
	background: $template_second;
	border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
	-webkit-animation: movebounce 3s linear infinite;
    animation: movebounce 3s linear infinite;
    opacity: 0.10;
    z-index: -1;
}
.rocket {
	position: absolute;
	left: -120px;
	bottom: 20rem;
    z-index: -1;

    img {
        width: 85%;
        -webkit-animation: movebounce 3s linear infinite;
        animation: movebounce 3s linear infinite;
    }
}
.vector {
	position: absolute;
	left: -13rem;
	bottom: -14rem;
	-webkit-animation: movebounce 3s linear infinite;
    animation: movebounce 3s linear infinite;
    z-index: -1;
    opacity: 0.2;
}
@keyframes movebounce {
    0% {
        transform: translateY(0px); 
    }
    50% {
        transform: translateY(15px); 
    }
    100% {
        transform: translateY(0px); 
    } 
}
@keyframes moveleftbounce {
    0% {
        transform: translateX(0px); 
    }
    50% {
        transform: translateX(15px); 
    }
    100% {
        transform: translateX(0px); 
    } 
}

/*=============================
Features CSS
===============================*/
.features-area {
    position: relative;
    z-index: 1;
    overflow: hidden;

    .about-image {
        margin-top: 0;
    }
    .about-text {
        margin-top: 50px;
    }
    .rocket {
        bottom: 45rem;
    }
}
.features-text {
    h3 {
        text-transform: capitalize;
        margin-bottom: 20px;
        font: {
            size: 30px;
            weight: 700;
        };
    }
    ul {
        padding: 0;
        margin: 20px 0 25px;
        list-style-type: none;

        li {
            position: relative;
            color: $paragraph_color;
            margin-bottom: 15px;
            padding-right: 25px;

            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 3px;
                width: 15px;
                height: 15px;
                background: $template_color;
                -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
.single-features {
    i {
        background: #f4f6fd;
        display: inline-block;
        text-align: center;
        width: 80px;
        height: 80px;
        font-size: 35px;
        color: $template_color;
        line-height: 80px;
        -webkit-clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
        clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
        -webkit-transition: $transition;
        -moz-transition: $transition;
        transition: $transition;
    }
    h3 {
        font: {
            size: 22px;
            weight: 700;
        };
        margin: {
            bottom: 15px;
            top: 30px;
        };
    }
    p {
        margin-bottom: 20px;
    }
    &:hover {
        i {
            background: $template_color;
            color: $white_color;
        }
    }
}
.features-area-three {
    position: relative;
    z-index: 1;
	padding-bottom: 55px;
}
.features-box {
	margin-bottom: 45px;
	position: relative;
    padding-right: 75px;

    i {
        background: #f4f6fd;
        display: inline-block;
        width: 55px;
        height: 55px;
        font-size: 30px;
        color: $template_color;
        line-height: 60px;
        -webkit-transition: $transition;
        -moz-transition: $transition;
        transition: $transition;
        text-align: center;
        position: absolute;
        right: 0;
        top: 0;
    }
    h3 {
        margin-bottom: 12px;
        font: {
            size: 22px;
            weight: 700;
        };
    }
    p {
        margin-bottom: 20px;
    }
    &:hover {
        i {
            background: $template_color;
            color: $white_color;
        }
    }
}

/*=============================
Services CSS
===============================*/
.services-area {
    padding-bottom: 70px;
    position: relative;
    z-index: 1;

    &.bg {
        background: {
            image: url(../../assets/img/bg-pattern-2.png);
            attachment: fixed;
        };
    }
}
.single-services {
	text-align: center;
	background: $white_color;
	padding: 25px;
    margin-bottom: 30px;
	border-radius: 5px;
	-webkit-box-shadow: 7px 5px 30px rgba(72,73,121,0.15);
	box-shadow: 7px 5px 30px rgba(72,73,121,0.15);
	-webkit-transition: $transition;
	-moz-transition: $transition;
    transition: $transition;

    &:hover {
        background: $template_color;
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);

        .icon {
            border-radius: 50%;
        } 
        p, h3 {
            color: $white_color;
        }
        .read-more-btn {
            color: $white_color;

            &::before {
                background: $white_color;
            }
        }

    }
    .icon {
        background: #f4f6fd;
        display: inline-block;
        width: 80px;
        height: 80px;
        font-size: 40px;
        color: $template_color;
        line-height: 80px;
        border-radius: 5px;
        -webkit-transition: $transition;
        -moz-transition: $transition;
        transition: $transition;
    }
    h3 {
        font: {
            size: 22px;
            weight: 700;
        };
        margin: {
            bottom: 15px;
            top: 25px;
        };
    }
    p {
        margin-bottom: 20px;
    }
    p, h3 {
        -webkit-transition: $transition;
        -moz-transition: $transition;
        transition: $transition;
    }
}
.services-box {
	text-align: center;
	background: $white_color;
	padding: 25px;
    margin-bottom: 30px;
	border-radius: 5px;
	-webkit-box-shadow: 7px 5px 30px rgba(72,73,121,0.15);
	box-shadow: 7px 5px 30px rgba(72,73,121,0.15);
	-webkit-transition: $transition;
	-moz-transition: $transition;
    transition: $transition;

    .icon {
        border: 1px solid $template_color;
        display: inline-block;
        width: 80px;
        height: 80px;
        font-size: 40px;
        position: relative;
        color: $template_color;
        line-height: 80px;
        border-radius: 50%;
        z-index: 1;
        margin: 20px 0 20px;
        -webkit-transition: $transition;
        -moz-transition: $transition;
        transition: $transition;

        &::before {
            display: block;
            content: "";
            position: absolute;
            width: 120px;
            height: 120px;
            top: -21px;
            left: -21px;
            z-index: -1;
            border: 0.5em solid $white_color;
            border-radius: 50%;
            -webkit-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
            box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
            -webkit-transition: $transition;
            -moz-transition: $transition;
            transition: $transition;
        }
    }
    h3 {
        font: {
            size: 22px;
            weight: 700;
        };
        margin: {
            bottom: 15px;
            top: 30px;
        };
    }
    p {
        margin-bottom: 20px;
    }
    &:hover {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);

        .icon {
            color: $white_color;
            background-color: $template_color;
        }
    }
}

/*=============================
Analysis CSS
===============================*/
.analysis-area {
	position: relative;
	overflow: hidden;
}
.analysis-form {
	background: $white_color;
	-webkit-box-shadow: 5px 7px 30px rgba(72,73,121,0.15);
	box-shadow: 5px 7px 30px rgba(72,73,121,0.15);
	padding: 40px;
	border-radius: 5px;
    text-align: center;

    h3 {
        margin-bottom: 30px;
        font: {
            size: 28px;
            weight: 700;
        };
    }
    form {
        .btn {
            display: block;
            width: 100%;
            padding: 13px 40px;
            border-radius: 5px;
            margin-top: 20px;
        }
    }
}
.analysis-text {
	position: relative;
    z-index: 1;
    padding-right: 25px;

    span {
        color: $template_color;
        text-transform: capitalize;
        font: {
            size: 16px;
            weight: 400;
        };
    }
    h3 {
        margin: 13px 0 20px;
        line-height: 45px;
        font: {
            size: 40px;
            weight: 700;
        };
    }
    ul {
        padding: 0;
        margin: 20px 0 0;
        list-style-type: none;

        li {
            position: relative;
            color: $paragraph_color;
            margin-bottom: 15px;
            padding-right: 30px;
    
            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 6px;
                width: 17px;
                height: 17px;
                background: $template_color;
                -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
.pattern-1 {
	position: absolute;
	bottom: 0;
	left: 0;
}

/*=============================
Project CSS
===============================*/
.shorting-menu {
	display: inline-block;
	-webkit-box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
	box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
	padding: 18px 20px;
    margin-bottom: 40px;
    border-radius: 50px;

    &.text-center {
        max-width: 700px;
        display: block;
        margin: {
            left: auto;
            right: auto;
        };
    }
    .filter {
        border: none;
        background: #f4f4f4;
        padding: 9px 24px;
        text-transform: capitalize;
        cursor: pointer;
        -o-transition: $transition;
        -ms-transition: $transition;
        -webkit-transition: $transition;
        -moz-transition: $transition;
        transition: $transition;
        border-radius: 30px;
        color: $black_color;
        margin-left: 10px;
        font: {
            size: 16px;
            weight: 600;
        };
        &.mixitup-control-active, &:hover {
            background: $template_color;
	        color: $white_color;
        }
        &:last-child {
            margin-left: 0;
        }
    }
}
.single-work {
	overflow: hidden;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: $black_color;
        left: 0;
        opacity: 0;
        visibility: hidden;
        -moz-transition: $transition;
        -webkit-transition: $transition;
        transition: $transition;
    }
    .work-content {
        position: absolute;
        bottom: -115px;
        width: 100%;
        background: $white_color;
        left: 0;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        opacity: 0;
        visibility: hidden;
        -moz-transition: $transition;
        -webkit-transition: $transition;
        transition: $transition;

        h3 {
            margin-bottom: 10px;
            font: {
                size: 22px;
                weight: 600;
            };
        }
        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                display: inline-block;
                position: relative;
                margin: {
                    left: 8px;
                    right: 8px;
                };
                a {
                    display: inline-block;
                }
                &::before {
                    content: '';
                    position: absolute;
                    left: -10px;
                    top: 10px;
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background-color: $black_color;
                }
                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
    .popup-btn, .link-btn {
        position: absolute;
        top: 0;
        right: 40px;
        width: 45px;
        text-align: center;
        height: 45px;
        line-height: 45px;
        border-radius: 50%;
        z-index: 2;
        color: $white_color;
        font-size: 22px;
        opacity: 0;
        visibility: hidden;
        border: 1px solid $white_color;
        -moz-transition: $transition;
        -webkit-transition: $transition;
        transition: $transition;

        &:hover {
            background: $template_color;
            border-color: $template_color;
        }
    }
    .link-btn {
        top: 40px;
        right: auto;
        left: 0;
    }
    &:hover {
        .link-btn {
            left: 40px;
        }
        &::before {
            opacity: .80;
            visibility: visible;
        }
        .work-content {
            opacity: 1;
            visibility: visible;
            bottom: 0;
        }
        .popup-btn, .link-btn {
            top: 40px;
            opacity: 1;
            visibility: visible;
            -webkit-animation: movebounce 3s linear infinite;
            animation: movebounce 3s linear infinite;
        }
    }
}
.project-slides {
    &.owl-theme {
        .owl-dots {
            margin-top: 45px !important;
        }
    }
}

/*=============================
Project Details CSS
===============================*/
.project-details {
    margin-top: 35px;

    h3 {
        margin-bottom: 15px;
        font: {
            size: 25px;
            weight: 700;
        };
    }
    p {
        font-size: 16px;
        margin-bottom: 15px;
    }
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        &.features {
            margin-top: 20px;

            li {
                position: relative;
                color: $paragraph_color;
                margin-bottom: 10px;
                padding-right: 24px;

                &::before {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 4px;
                    width: 15px;
                    height: 15px;
                    background: $template_color;
                    -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.project-info {
    ul {
        li {
            position: relative;
            padding-right: 50px;
            margin-bottom: 20px;
            color: $paragraph_color;

            &:last-child {
                margin-bottom: 0;
            }
            i {
                position: absolute;
                right: 0;
                top: 5px;
                color: $template_color;
                font-size: 30px;
            }
            span {
                display: block;
                color: $black_color;
                font: {
                    size: 18px;
                    weight: 700;
                };
            }
        }
    }
}

/*=============================
Working Process CSS
===============================*/
.single-work-process {
	text-align: center;
	background: $white_color;
	padding: 25px;
	border-radius: 5px;
    position: relative;
	-webkit-box-shadow: 7px 5px 30px rgba(72,73,121,0.15);
    box-shadow: 7px 5px 30px rgba(72,73,121,0.15);

    

    &::before {
        content: '';
        position: absolute;
        z-index: 1;
        background: url(../../assets/img/arrow-big.png) no-repeat center center;
        width: 217px;
        height: 210px;
        top: -20px;
        left: -120px;
            -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
    }
    .icon {
        background: #f4f6fd;
        display: inline-block;
        width: 80px;
        height: 80px;
        position: relative;
        z-index: 1;
        font-size: 40px;
        color: $template_color;
        line-height: 80px;
        -webkit-clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
        clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
        -webkit-transition: $transition;
        -moz-transition: $transition;
        transition: $transition;

        &::before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            width: 0;
            height: 0;
            background: $template_color;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            -webkit-transition: $transition;
            -moz-transition: $transition;
            transition: $transition;
        }
    }
    h3 {
        margin-top: 30px;
        margin-bottom: 0;
        font: {
            size: 22px;
            weight: 700;
        };
    }
}
.col-lg-3 {
    &:nth-child(4) {
        .single-work-process {
            &::before {
                display: none;
            }
        }
    }
}

/*=============================
Team CSS
===============================*/
.team-area {
    padding-bottom: 70px;
}
.single-team {
	text-align: center;
	background: $white_color;
	-webkit-box-shadow: 7px 5px 30px rgba(72,73,121,0.15);
	box-shadow: 7px 5px 30px rgba(72,73,121,0.15);
	border-radius: 5px;
    margin-bottom: 30px;
	padding: 25px 15px;
	-webkit-transition: $transition;
	-moz-transition: $transition;
    transition: $transition;

    img {
        border-radius: 50%;
    }
    .team-content {
        margin-top: 25px;

        h3 {
            margin-bottom: 8px;
            font: {
                size: 22px;
                weight: 700;
            };
        }
        span {
            display: block;
            color: $paragraph_color;
        }
        ul {
            padding: 0;
            margin: 15px 0 0;
            list-style-type: none;

            li {
                display: inline-block;
                margin: 0 4px;

                a {
                    display: block;
                }
            }
        }
        h3, span, ul li a {
            -webkit-transition: $transition;
            -moz-transition: $transition;
            transition: $transition;
        }
    }
    &:hover {
        background: $template_color;

        .team-content {
            h3, span, ul li a {
                color: $white_color;
            }
        }
    }
}

/*=============================
Testimonial CSS
===============================*/
.testimonial-area {
    position: relative;
    overflow: hidden;
    z-index: 1;

    .pattern-1 {
        left: auto;
        right: 0;
        bottom: auto;
        top: 0;
    }
}
.single-feedback {
	background: $white_color;
	box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
    padding: 20px;
    z-index: 1;
	border-radius: 5px;
	position: relative;
	-moz-transition: $transition;
	-webkit-transition: $transition;
    transition: $transition;
    margin: {
        bottom: 30px;
        top: 25px;
    };
    .client-info {
        position: relative;
        padding-right: 118px;
        margin-bottom: 30px;

        .img {
            position: absolute;
            right: 0;
            top: -45px;
            width: 100px;
            -moz-transition: $transition;
            -webkit-transition: $transition;
            transition: $transition;

            img {
                border-radius: 50%;
            }
        }
        h4 {
            margin-bottom: 0;
            font: {
                size: 20px;
                weight: 600;
            };
        }
        span {
            display: block;
            font-size: 15px;
            color: $paragraph_color;
            margin-top: 5px;
        }
    }
    p, .client-info h4, .client-info span {
        -moz-transition: $transition;
        -webkit-transition: $transition;
        transition: $transition;
    }
    i {
        font-size: 104px;
        position: absolute;
        top: 50%;
        right: 50%;
        color: #6f8ba4;
        z-index: -1;
        opacity: .10;
        -moz-transition: $transition;
        -webkit-transition: $transition;
        transition: $transition;
        margin: {
            top: -6px;
            right: -70px;
        };
    }
    &:hover {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
        background: $template_color;

        .client-info {
            .img {
                top: -33px;
                -webkit-animation: movebounce 3s linear infinite;
                animation: movebounce 3s linear infinite;
            }
        }
        p, .client-info h4, .client-info span {
           color: $white_color;
        }
        i {
            color: $white_color;
            opacity: .14;
        }
    }
}

/*=============================
FunFacts CSS
===============================*/
.funfacts-area {
    position: relative;
    background: $template_color;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: -1;
        height: 100%;
        background-image: url(../../assets/img/bg-pattern-2.png);
    }
}
.fun-fact {
	position: relative;
	padding-right: 65px;
	-moz-transition: $transition;
	-webkit-transition: $transition;
    transition: $transition;

    i {
        font-size: 45px;
        color: $white_color;
        position: absolute;
        right: 0;
        top: 5px;
        -webkit-animation: movebounce 3s linear infinite;
        animation: movebounce 3s linear infinite;
        -moz-transition: $transition;
        -webkit-transition: $transition;
        transition: $transition;
    }
    h3 {
        direction: ltr;
    text-align: right;
        color: $white_color;
        margin: {
            bottom: 0;
            top: 5px;
        };
        font: {
            size: 45px;
            weight: 700;
        };
        .sign {
            display: inline-block;
            font-size: 40px;
        }
    }
    p {
        font-size: 18px;
        color: $white_color;
        display: block;
    }
    &:hover {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

/*=============================
Check Your Website CSS
===============================*/
.check-your-website {
    position: relative;
	overflow: hidden;
	z-index: 1;
    background: $template_color;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: -1;
        height: 100%;
        background-image: url(../../assets/img/bg-pattern-2.png);
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: url(../../assets/img/seoscore.png) center center;
        z-index: -1;
        opacity: .7;
        -webkit-animation: movebounce 3s linear infinite;
        animation: movebounce 3s linear infinite;
    }
}
.check-your-website-content {
    position: relative;
    text-align: center;
    max-width: 820px;
    margin: {
        left: auto;
        right: auto;
    };
    h2 {
        text-transform: capitalize;
        color: $white_color;
        margin-bottom: 20px;
        font: {
            size: 40px;
            weight: 700;
        };
        span {
            color: $black_color;
            display: inline-block;
        };
    }
    p {
        font-size: 20px;
        color: $white_color;
        margin-bottom: 30px;
    }
    .form-control {
        border-radius: 50px;
        border: none;
        height: 60px;
    }
    .btn {
        margin-top: 25px;
        padding: 17px 40px 15px;
    }
    .btn-primary {
        background: $white_color;
        color: $template_color;

        &:hover, &:focus {
            background: $black_color;
            color: $white_color;
        }
    }
}

/*=============================
Pricing CSS
===============================*/
.pricing-area {
    position: relative;
    z-index: 1;
    overflow: hidden;

    .pattern-1 {
        left: auto;
        right: 0;
        bottom: auto;
        top: 0;
    }
}
.tab-slider-nav {
	text-align: center;
	overflow: hidden;
	width: 100%;
	margin-bottom: 40px;
}
.tab-slider-tabs {
	display: inline-block;
	margin-bottom: 0;
	padding-right: 0;
	list-style: none;
	position: relative;
	border-radius: 35px;
    background: $white_color;

    &::after {
        content: "";
        width: 50%;
        background: $template_color;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        -moz-transition: $transition;
        -webkit-transition: $transition;
        transition: $transition;
        border-radius: 35px;
    }
    &.slide::after {
        right: 50%;
    }
}
.tab-slider-trigger {
	font-weight: 700;
	color: #222222;
	text-transform: uppercase;
	padding: 11px 20px 10px;
	position: relative;
	z-index: 2;
	cursor: pointer;
	display: inline-block;
	-moz-transition: $transition;
	-webkit-transition: $transition;
    transition: $transition;

    &.active {
        color: $white_color;
    }
}
.tab-slider-container .row {
    width: 100%;
}
.pricingTable {
	padding-bottom: 30px;
	background: $white_color;
	text-align: center;
	z-index: 1;
	position: relative;
	border-radius: 5px;
    background-image: url(../../assets/img/patt.png);
    background-position: center center;
	overflow: hidden;
	-webkit-box-shadow: 7px 5px 30px 0 rgba(72,73,121,0.15);
	box-shadow: 7px 5px 30px 0 rgba(72,73,121,0.15);
	-moz-transition: $transition;
	-webkit-transition: $transition;
    transition: $transition;

    .pricingTable-header {
        padding: 30px 15px 45px;
        background: $template_color;
        -webkit-clip-path: polygon(50% 100%, 100% 60%, 100% 0, 0 0, 0 60%);
        clip-path: polygon(50% 100%, 100% 60%, 100% 0, 0 0, 0 60%);
        position: relative;

        &::before {
            content: "";
            width: 400px;
            height: 400px;
            border-radius: 50%;
            position: absolute;
            right: -50%;
            top: -130%;
            background: repeating-radial-gradient(rgba(255,255,255,0.05), rgba(255,255,255,0.2) 20%);
            -moz-transition: $transition;
            -webkit-transition: $transition;
            transition: $transition;
        }
    }
    .title {
        color: $white_color;
        text-transform: uppercase;
        margin-bottom: 15px;
        font: {
            size: 22px;
            weight: 700;
        };
    }
    .price-value {
        display: block;
        color: $white_color;
        margin-bottom: 30px;
        font: {
            size: 40px;
            weight: 700;
        };
        span {
            font-size: 15px;
            color: $white_color;
            margin-left: -10px;
        }
    }
    .pricing-content {
        padding: 30px 25px;
        margin-bottom: 0;
        list-style: none;

        li {
            color: $black_color;
            text-transform: capitalize;
            border-bottom: 1px solid #eeeeee;
            margin-bottom: 12px;
            padding-bottom: 12px;
            font: {
                size: $font_size;
                weight: 600;
            };
            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
    }
    &:hover {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);

        .pricingTable-header {
            &::before {
                right: 50%;
            }
        }
    }
}
.pricing-plan {
	background: $white_color;
	padding-bottom: 20px;
	position: relative;
	-webkit-box-shadow: 7px 5px 30px 0 rgba(72,73,121,0.15);
	box-shadow: 7px 5px 30px 0 rgba(72,73,121,0.15);
	text-align: center;
	z-index: 1;
	margin-top: 20px;
	border-radius: 5px;
	-moz-transition: $transition;
	-webkit-transition: $transition;
    transition: $transition;

    h3 {
        padding: 20px;
        margin-bottom: 50px;
        background: $template_color;
        color: $white_color;
        border-radius: 5px 5px 0 0;
        font: {
            size: 22px;
            weight: 700;
        };
        span {
            display: block;
            color: $template_color;
            background: $white_color;
            height: 100px;
            width: 100px;
            margin: 20px auto -65px;
            border-radius: 50%;
            box-shadow: 10px 15px 30px 0 rgba(72,73,121,0.15);
            line-height: 100px;
            font: {
                size: 30px;
                weight: 700;
            };
        }
    }
    ul {
        margin: 80px 0 20px;
        padding: 0;
        list-style: none;

        li {
            border-top: 1px solid #eeeeee;
            padding: 12px 0;
            color: $black_color;
            font-weight: 600;

            &:last-child {
                padding-bottom: 0;
            }
        }
    }
    &#most-popular {
        top: -20px;
        padding: 30px 0 50px;
        -webkit-box-shadow: 10px 15px 30px 0 rgba(72,73,121,0.15);
        box-shadow: 10px 15px 30px 0 rgba(72,73,121,0.15);
        z-index: 2;

        h3 {
            margin-top: -30px;
            padding-top: 30px;
        }
    }
    &:hover {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

/*=============================
Services Details Area CSS
===============================*/
.services-details-overview {
    align-items: center!important;
    display: flex;
    flex-wrap: wrap;
    margin: {
        right: -25px;
        left: -25px;
        bottom: 60px;
    };
    &:last-child {
        margin-bottom: 0;
    }
    .services-details-desc {
        flex: 0 0 50%;
        max-width: 50%;
        padding: {
            right: 25px;
            left: 25px;
        };
        h3 {
            margin-bottom: 15px;
            font: {
                size: 24px;
                weight: 700;
            };
        }
        .features-text {
            margin-top: 25px;
    
            h4 {
                margin-bottom: 15px;
                font: {
                    size: 17px;
                    weight: 600;
                };
                i {
                    font-size: 25px;
                    color: $template_color;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }
    .services-details-image {
        flex: 0 0 50%;
        max-width: 50%;
        padding: {
            right: 15px;
            left: 15px;
        };
    }
}

/*=============================
Partner CSS
===============================*/
.partner-area {
    overflow: hidden;
    z-index: 1;
    position: relative;
}
.partner-item {
    text-align: center;
    
    a {
        display: block;

        img {
            display: inline-block !important;
            width: auto !important;
        }
    }
}
.partner-slides {
    &.owl-theme {
        .owl-dots {
            margin-top: 40px !important;
        }
    }
}

/*=============================
Blog CSS
===============================*/
.blog-area {
    position: relative;
    overflow: hidden;
    z-index: 1;

    .pattern-1 {
        bottom: auto;
        top: 0;
    }
}
.single-blog-item {
	position: relative;
	padding: 25px;
	margin-bottom: 30px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #eeeeee;
	-moz-transition: $transition;
	-webkit-transition: $transition;
    transition: $transition;
    background: {
        size: cover;
        position: center center;
        repeat: no-repeat;
    };
    &::before {
        content: '';
        position: absolute;
        left: 0;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        top: 0;
        width: 100%;
        height: 100%;
        background: #000000;
        opacity: .66;
    }
    span, p, h3 {
        position: relative;
        -o-transition: $transition;
        -ms-transition: $transition;
        -moz-transition: $transition;
        -webkit-transition: $transition;
        transition: $transition;
    }
    span {
        font-weight: 600;
        color: $white_color;
    }
    h3 {
        line-height: 1.4;
        font: {
            size: 22px;
            weight: 700;
        };
        margin: {
            bottom: 15px;
            top: 15px;
        };
        a {
            display: inline-block;
            color: $white_color;

            &:hover {
                color: $template_color !important;
            }
        }
    }
    p {
        color: $white_color;
        opacity: 0.95;
    }
    .link-btn {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 45px;
        height: 40px;
        display: inline-block;
        line-height: 44px;
        text-align: center;
        box-shadow: 0px 0px 25px 0px rgba(0, 27, 103, 0.3);
        border-radius: 0 50% 0 0;
        background-color: $white_color;
        opacity: 0;
        visibility: hidden;
    }
    &:hover {
        .link-btn {
            opacity: 1;
            visibility: visible;
        }
    }
    &.bg-1 {
        background-image: url(../../assets/img/blog-img1.jpg);
    }
    &.bg-2 {
        background-image: url(../../assets/img/blog-img2.jpg);
    }
    &.bg-3 {
        background-image: url(../../assets/img/blog-img3.jpg);
    }
    &.bg-4 {
        background-image: url(../../assets/img/blog-img4.jpg);
    }
    &.bg-5 {
        background-image: url(../../assets/img/blog-img5.jpg);
    }
    &.bg-6 {
        background-image: url(../../assets/img/blog-img6.jpg);
    }
}
.single-blog-post {
	margin-bottom: 30px;
    border-radius: 5px;
	-moz-transition: $transition;
	-webkit-transition: $transition;
    transition: $transition;
    
    .blog-image {
        display: block;
    }
    .blog-post-content {
        margin-top: 25px;

        span {
            font-weight: 600;
        }
        h3 {
            line-height: 1.4;
            font: {
                size: 22px;
                weight: 700;
            };
            margin: {
                bottom: 12px;
                top: 12px;
            };
            a {
                display: inline-block;
            }
        }
        p {
            margin-bottom: 18px;
        }
    }
}

/*=============================
Blog Details CSS
===============================*/
.blog-details {
    .thumb {
        position: relative;
    }
    .date {
        position: absolute;
        right: 0;
        bottom: 0;
        text-transform: uppercase;
        background: $template_color;
        color: $white_color;
        padding: 12px 20px 10px;
        border-radius: 10px 0 0 0;
        font: {
            size: 22px;
            weight: 700;
        };
    }
}
.blog-details-heading {
    margin: 30px 0 0;

    h3 {
        margin-bottom: 15px;
        font: {
            size: 25px;
            weight: 700;
        };
    }
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            display: inline-block;
            margin-left: 15px;
            color: $paragraph_color;
            font: {
                size: 15px;
                weight: 500;
            };
            i {
                color: $template_color;
                margin-left: 2px;
            }
        }
    }
}
.blog-details-content {
    p {
        margin-top: 15px;
        font-size: 16px;
        line-height: 1.8;
    }
    .blockquote {
        margin: 25px 0;
        background: $template_color;
        padding: 25px;
        color: $white_color;

        p {
            color: $white_color;
            font-size: 18px;
            margin-top: 0;
            line-height: 1.6;
        }
        .blockquote-footer {
            color:  $white_color;
            margin-top: 15px;
            text-transform: uppercase;
            font: {
                size: 20px;
                weight: 700;
            };
        }
    }
    .title {
        margin-bottom: 35px;
        font: {
            size: 22px;
            weight: 700;
        };
    }
}
.blog-details-meta {
	margin: 25px 0;
    padding: 20px 0;
    border: {
        top: 1px solid #eeeeee;
        bottom: 1px solid #eeeeee;
    };
    .tags {
        display: inline-block;

        li {
            a {
                border: 1px solid #eeeeee;
                padding: 5px 14px;
                border-radius: 6px;
                display: inline-block;
                color: #6f8ba4;
                font-size: 15px;
                margin-right: 3px;

                &:hover {
                    background: $template_color;
                    color: $white_color;
                    border-color: $template_color;
                }
            }
        }
    }
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        .title {
            color: $paragraph_color;
            font: {
                size: 18px;
                weight: 500;
            };
            margin: {
                left: 5px;
                bottom: 0;
            };
        }
        li {
            display: inline-block;
        }
    }
    .share {
        float: left;

        li {
            a {
                color: #6f8ba4;
                border: 1px solid #eeeeee;
                width: 35px;
                font-size: 14px;
                height: 35px;
                text-align: center;
                line-height: 34px;
                display: block;
                border-radius: 50%;
                margin-right: 5px;

                &:hover {
                    background: $template_color;
                    color: $white_color;
                    border-color: $template_color;
                }
            }
        }
    }
}
.blog-details-comments {
    .single-comments {
        position: relative;
        padding-right: 130px;

        .thumb {
            position: absolute;
            right: 0;
            top: 0;

            img {
                border-radius: 50%;
            }
        }
        .content {
            h4 {
                font: {
                    size: 18px;
                    weight: 700;
                };
            }
            span {
                font-size: 14px;
                color: #6f8ba4;
                display: block;
                margin: 8px 0 -5px;
            }
            p {
                margin-bottom: 15px;
            }
        }
    }
    .border {
        border-color: #eeeeee !important;
        margin: 25px 0;
    }
}
.single-comments.left-mr {
    margin: {
        right: 80px;
        top: 35px;
    };
}
.blog-details-comments-form {
	margin-top: 25px;
}

/*=============================
Page Title CSS
===============================*/
.page-title {
   // background: #f4f6fd;
    z-index: 1;
	position: relative;
	overflow: hidden;
	background-image: url(../../assets/img/patt.png);
    text-align: center;
    padding: {
        bottom: 100px;
        top: 100px;
    };
    h3 {
        margin-bottom: 15px;
        font: {
            size: 40px;
            weight: 700;
        };
    }
    ul {
        padding: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            display: inline-block;
            color: $black_color;
            text-transform: capitalize;
            margin: {
                left: 2px;
                right: 2px;
            };
            &.dot-divider {
                display: inline-block;
                height: 4px;
                width: 4px;
                background: $template_color;
                border-radius: 50%;
                margin: 0 5px;
                vertical-align: middle;
            }
            font: {
                size: 15px;
                weight: 600;
            };
        }
    }
    .bg-pattern {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: -1;
        height: 100%;
    }
}

/*=============================
History CSS
===============================*/
.timeline {
	list-style-type: none;
	padding: 0;
	position: relative;
    margin-bottom: 0;

    &::before {
        top: 0;
        bottom: 0;
        position: absolute;
        content: " ";
        width: 2px;
        background: $template_color;
        right: 50%;
        margin-right: -1.5px;
        border-radius: 5px;
    }
    > li {
        margin-bottom: 60px;
        position: relative;
        width: 48%;
        float: right;
        clear: right;
        text-align: left;

        &:nth-child(2) {
            margin-top: 110px;
        }
        &:last-child {
            margin-bottom: 0;
            float: none;
        }
        > .timeline-panel {
            width: calc(100% - 25px);
            width: -moz-calc(100% - 25px);
            width: -webkit-calc(100% - 25px);
            float: right;
            position: relative;
            background: $white_color;
            padding: 30px 20px;
            -webkit-box-shadow: 7px 5px 30px 0 rgba(72,73,121,0.15);
            box-shadow: 7px 5px 30px 0 rgba(72,73,121,0.15);
            -moz-transition: $transition;
            -webkit-transition: $transition;
            transition: $transition;

            &:hover {
                -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
            }
            &::before {
                position: absolute;
                top: 26px;
                left: -15px;
                display: inline-block;
                content: " ";
                border: {
                    top: 15px solid transparent;
                    right: 15px solid $white_color;
                    left: 0 solid $white_color;
                    bottom: 15px solid transparent;
                };
            }
        }
        > .timeline-badge {
            width: 24px;
            height: 24px;
            text-align: center;
            position: absolute;
            top: 29px;
            left: -34px;
            z-index: 100;
            background: $template_color;
            -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        }
        &.timeline-inverted {
            float: left;
            clear: left;
            text-align: right;
    
            > .timeline-badge {
                right: -35px;
            }
            > .timeline-panel {
                float: left;

                &::before {
                    right: -15px;
                    left: auto;
                    border: {
                        right-width: 0;
                        left-width: 15px;
                    };
                }
                &::after {
                    right: -14px;
                    left: auto;
                    border: {
                        right-width: 0;
                        left-width: 14px;
                    };
                }
            }
            .timeline-footer {
                text-align: right;
            }
        }
    }
    .timeline-title {
        margin-top: 0;
        color: inherit;
    }
    .timeline-heading {
        h3 {
            margin-bottom: 12px;
            font: {
                size: 22px;
                weight: 700;
            };
        }
    }
    .timeline-body > p {
        font-size: 16px;
        margin: {
            bottom: 35px;
            top: 15px;
        };
    }
    .timeline-footer {
        position: absolute;
        background: #f4f6fd;
        width: 100%;
        right: 0;
        bottom: 0;
        height: auto;
        text-align: left;
        padding: 10px 15px;
    
        p {
            font-weight: 700;
            color: $template_color;
        }
    }
}

/*=============================
Sidebar CSS
===============================*/
.side-bar {
    .widget-box {
        background: $white_color;
        -webkit-box-shadow: 7px 5px 30px rgba(72,73,121,0.15);
        box-shadow: 7px 5px 30px rgba(72,73,121,0.15);
        padding: 30px 25px;
        position: relative;
        border-radius: 5px;
        margin-bottom: 30px;

        .title {
            text-transform: capitalize;
            margin-bottom: 35px;
            position: relative;
            font: {
                size: 22px;
                weight: 700;
            };
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 2px;
                width: 17px;
                height: 17px;
                background: $template_color;
                -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            }
        }
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }
    }
    .search-box {
        form {
            position: relative;
        }
        button {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 50px;
            cursor: pointer;
            border: none;
            background: $template_color;
            color: $white_color;
            outline: 0 !important;
        }
    }
    .categories-box {
        ul {
            li{
                &:last-child {
                    a {
                        margin-bottom: 0;
                    }
                }
                a {
                    position: relative;
                    margin-bottom: 10px;
                    display: inline-block;
                    font-weight: 600;
                    padding-right: 18px;

                    i {
                        position: absolute;
                        right: 0;
                        top: 4px;
                        -moz-transition: $transition;
                        -webkit-transition: $transition;
                        transition: $transition;
                    }
                }
            }
        }
    }
    .tags-box {
        .title {
            margin-bottom: 27px;
        }
        ul {
            li {
                display: inline-block;
                
                a {
                    border: 1px solid #ddd;
                    padding: 5px 15px;
                    margin-top: 8px;
                    border-radius: 5px;
                    font-weight: 600;
                    display: inline-block;
                    margin-left: 8px;

                    &:hover {
                        color: $white_color;
                        background: $template_color;
                        border-color: $template_color;
                    }
                }
            }
        }
    }
    .recent-post-box {
        ul {
            li {
                position: relative;
                overflow: hidden;
                margin-bottom: 15px;
                padding-right: 110px;

                &:last-child {
                    margin-bottom: 0;
                }
                .recent-post-thumb {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 100px;
                }
                .recent-post-desc {
                    h3 {
                        line-height: 23px;
                        font: {
                            size: 15px;
                            weight: 700;
                        };
                    }
                    .date {
                        display: block;
                        margin-top: 3px;
                        font-size: 14px;
                        color: $paragraph_color;
                    }
                }
            }
        }
    }
}

/*=============================
Pagination CSS
===============================*/
.pagination-area {
	text-align: center;
    margin-top: 40px;

    .pagination {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
}
.page-link {
	padding: 11px 20px;
	margin: 0 3px;
	color: #6f8ba4;
	background-color: $white_color;
	border: none;
	border-radius: 5px;
	font-size: 16px;
	-webkit-box-shadow: 0 2px 10px 0 #d8dde6;
    box-shadow: 0 2px 10px 0 #d8dde6;

    &:hover, &:focus{
        color: $white_color;
        background-color: $template_color;
    }
    &:focus {
        box-shadow: unset;
    }
}
.page-item {
    &.active .page-link {
        color: $white_color;
        background-color: $template_color;
    }
}

/*=============================
Subscribe CSS
===============================*/
.subscribe-area {
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: {
        attachment: fixed;
        image: url(../../assets/img/bg-pattern-2.png);
    };
    .pattern-1 {
        left: auto;
        right: 0;
        bottom: auto;
        top: 0;
    }
}
.newsletter {
    max-width: 750px;
	box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
	text-align: center;
	background: $white_color;
	padding: 35px;
    border-radius: 5px;
    margin: {
        left: auto;
        right: auto;
    };
    h2 {
        text-transform: capitalize;
        margin-bottom: 30px;
        font: {
            size: 40px;
            weight: 700;
        };
    }
    form {
        position: relative;

        .form-control {
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
            height: 75px;
            border-radius: 50px;
            padding-right: 25px;
            font-size: 17px;
            background: $white_color;
            border: none;
            max-width: unset;
        }
        .btn {
            position: absolute;
            left: 7px;
            top: 8px;
            height: 60px;
            width: 175px;
        }
    }
}

/*=============================
Error Page CSS
===============================*/
.error-area {
	text-align: center;
	background: rgba(255, 78, 0, .8);
}
.error {
	max-width: 550px;
    margin: 0 auto;
    h1 {
        font: {
            size: 268px;
            weight: 700;
        }
        opacity: .8;
        color: $white_color;
    }
    p {
        color: $white_color;
        font-size: 25px;
        margin: {
            top: 25px;
            bottom: 30px;
        }
    }
    form {
        position: relative;
    }
    .btn {
        position: absolute;
        left: 0;
        top: 0;
        color: $white_color;
        border-radius: 0;
        background: #000;
        border-color: #000;
        height: 100%;
        width: 60px;
        padding: 0;
    }
}

/*=============================
Contact CSS
===============================*/
.contact-box {
	background: $white_color;
	padding: 25px 95px 25px 25px;
	border-radius: 5px;
	position: relative;
	-webkit-box-shadow: 7px 5px 30px rgba(72,73,121,0.15);
	box-shadow: 7px 5px 30px rgba(72,73,121,0.15);
	-ms-transition: $transition;
	-o-transition: $transition;
	-moz-transition: $transition;
	-webkit-transition: $transition;
    transition: $transition;

    .icon {
        position: absolute;
        right: 25px;
        top: 25px;
        width: 50px;
        height: 50px;
        text-align: center;
        color: $template_color;
        line-height: 47px;
        border: 1px dashed $template_color;
        border-radius: 50%;
        font-size: 22px;
        -ms-transition: $transition;
        -o-transition: $transition;
        -moz-transition: $transition;
        -webkit-transition: $transition;
        transition: $transition;
    }
    .content {
        h3 {
            margin-bottom: 12px;
            font: {
                size: 20px;
                weight: 700;
            };
        }
        p {
            margin-bottom: 2px;
            font-size: 16px;

            a {
                color: #777777;
                text-decoration: none;
                display: inline-block;

                &:hover {
                    color: $template_color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &:hover {
        box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);

        .icon {
            color: $white_color;
            background: $template_color;
        }
    }
}
#map {
    margin: {
        bottom: 40px;
        top: 40px;
    }
    iframe {
        width: 100%;
        height: 400px;
        border: none;
        margin-bottom: -5px;
    }
}
.contact-text {
    h3 {
        margin-bottom: 0;
        font: {
            size: 22px;
            weight: 700;
        };
    }
    p {
        margin-top: 20px;
    }
    .social-links {
        padding: 0;
        margin: 0;
        list-style-type: none;
        margin-top: 20px;

        li {
            display: inline-block;
            margin-left: 5px;
            
            a {
                border: 1px solid $black_color;
                width: 32px;
                height: 32px;
                text-align: center;
                display: inline-block;
                line-height: 31px;
                border-radius: 50%;
    
                &:hover, &:focus {
                    background: $template_color;
                    color: $white_color;
                    border-color: $template_color;
                }
            }
        }
    }
}
#contactForm {
    .btn {
        padding: 12px 40px;
        margin-top: 15px;
        font-size: 18px;
    }
}

/*=============================
Repair Main Banner CSS
===============================*/
.repair-main-banner {
    position: relative;
    z-index: 1;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
        image: url(../../assets/img/slider-one.jpg);
    };
    padding: {
        top: 200px;
        bottom: 280px;
    };
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: $black_color;
        z-index: -1;
        opacity: .45;
    }
}
.repair-content-slides {
    max-width: 620px;
    margin-bottom: 10px;

    &.owl-theme {
        .owl-dots {
            line-height: .1;
            text-align: right;
            margin: {
                top: 70px !important;
                right: 15px;
            };
            .owl-dot {
                span {
                    width: 20px;
                    height: 3.5px;
                    margin: 0 8px 0 0;
                    border-radius: 0;
                    background: #e2e1e1;
                }
                &:hover {
                    span {
                        background: $white_color;
                    }
                }
                &.active {
                    span {
                        background: $white_color;
                        width: 30px;
                    }
                }
            }
        }
    }
}
.repair-banner-content {
    h2 {
        color: $white_color;
        line-height: 1.1;
        margin-bottom: 15px;
        font: {
            size: 57px;
            weight: 700;
        };
    }
    p {
        max-width: 550px;
        margin-bottom: 25px;
        color: $white_color;
        font-size: 17px;
    }
}

/*=============================
Repair Boxes Area CSS
===============================*/
.repair-boxes-area {
    background-color: transparent;
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin: {
        top: -130px;
        bottom: -30px;
    };
    &::before {
        content: '';
        position: absolute;
        right: 0;
        height: 100%;
        bottom: 0;
        width: 201%;
        background: $white_color;
        z-index: -1;
        transform: skewY(-177deg);
    }
}
.single-repair-box {
    text-align: center;
    background-color: $white_color;
    position: relative;
    z-index: 1;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 70px 20px;
    margin-bottom: 30px;

    i {
        font-size: 45px;
        color: $template_color;
    }
    h3 {
        font: {
            size: 22px;
            weight: 700;
        };
        margin: {
            top: 20px;
            bottom: 15px;
        };
    }
    .read-more-btn {
        margin-top: 20px;
    }
    .left {
        position: absolute;
        right: 0;
        z-index: -1;
        top: 0;
    }
    .right {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
    }
}

/*=============================
Repair About Area CSS
===============================*/
.repair-about-image {
    position: relative;
    padding-bottom: 180px;

    img {
        &:last-child {
            position: absolute;
            bottom: -5px;
            border: 5px solid #f8fbff;
            left: 0;
        }
    }
}
.repair-about-content {
    .section-title {
        margin-bottom: 25px;
    }
    .single-about-box {
        position: relative;
        margin-bottom: 25px;
        transition: $transition;
        background: #f8fbff;
        padding: 15px 60px 15px 15px;

        i {
            position: absolute;
            right: 15px;
            top: 15px;
            font-size: 30px;
            color: $template_color;
        }
        h3 {
            margin-bottom: 12px;
            font: {
                size: 18px;
                weight: 700;
            };
        }
        &:hover {
            background-color: $white_color;
            box-shadow: 0 10px 55px 5px rgba(137, 173, 255, 0.35);
            transform: translateY(-5px);
        }
    }
    .read-more-btn {
        margin-top: 20px;
    }
}

/*=============================
Gallery Area CSS
===============================*/
.gallery-area {
    padding-bottom: 70px;

    .section-title {
        margin-bottom: 40px;
    }
}
.single-gallery-item {
    overflow: hidden;
    margin-bottom: 30px;

    a {
        display: block;
        
        img {
            transition: all 1.5s ease-out;
        }
    }
    &:hover, &:focus {
        a {
            img {
                transform: scale(1.4);
            }
        }
    }
}
.gallery-shorting-menu {
    display: block;
    text-align: center;
    box-shadow: unset;
    padding: 0;
    background-color: transparent;
    border-radius: 0;

    .filter {
        border-radius: 0;
        background-color: transparent;
        border: 1px solid #eeeeee;
        color: $black_color;
        font-weight: 700;

        &:hover, &:focus, &.mixitup-control-active {
            background-color: $template_color;
            color: $white_color;
            border-color: $template_color;
        }
    }
}

/*=============================
Repair Services CSS
===============================*/
.repair-services-area {
    position: relative;
    z-index: 1;
    background-color: $black_blue_color;

    .section-title {
        h3 {
            color: $white_color;
        }
        p {
            color: $white_color;
        }
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: $white_color;
        z-index: -1;
        height: 11%;
    }
}
.single-repair-services {
    border: 1px solid #8096b0;
    padding: 30px;
    border-radius: 5px;
    transition: $transition;

    i {
        color: $white_color;
        font-size: 40px;
        transition: $transition;
    }
    h3 {
        transition: $transition;
        color: $white_color;
        font: {
            size: 22px;
            weight: 700;
        };
        margin: {
            bottom: 15px;
            top: 20px;
        };
    }
    p {
        color: $white_color;
        opacity: .90;
        transition: $transition;
    }
    .read-more-btn {
        margin-top: 15px;
    }
    &:hover, &:focus {
        background-color: $white_color;
        border-color: $white_color;

        i {
            color: $template_color;
        }
        h3 {
            color: $black_color;
        }
        p {
            color: $paragraph_color;
        }
    }
}
.repair-services-slides {
    &.owl-theme {
        .owl-dots {
            line-height: .1;
            margin-top: 45px !important;
        }
    }
}
.repair-services-inner {
    border-radius: 5px;
    margin-top: 80px;
    background-color: $template_color;
    padding: {
        top: 40px;
        bottom: 40px;
        left: 25px;
        right: 25px;
    };
    .single-inner-services {
        position: relative;
        padding-right: 50px;

        i {
            font-size: 35px;
            color: $white_color;
            position: absolute;
            right: 0;
            top: 0;
        }
        h3 {
            transition: $transition;
            color: $white_color;
            font: {
                size: 22px;
                weight: 700;
            };
            margin: {
                bottom: 15px;
                top: 20px;
            };
        }
        p {
            color: $white_color;
            opacity: .90;
            transition: $transition;
        }
    }
    .col-lg-4 {
        &:nth-child(2) {
            border: {
                left: 1px solid #d1613c;
                right: 1px solid #d1613c;
            }
        }
    }
}

/*=============================
Repair Area CSS
===============================*/
.repair-partner-area {
    background-color: $black_blue_color;
    padding: {
        top: 50px;
        bottom: 50px;
    };
}
.single-repair-client {
    text-align: center;

    a {
        display: inline-block;

        img {
            width: auto !important;
            display: inline-block !important;
        }
    }
}

/*=============================
Repair Feedback CSS
===============================*/
.single-repair-feedback {
    border: 1px solid #eeeeee;
    padding: 30px;
    border-radius: 5px;

    .client-info {
        margin-bottom: 25px;
        position: relative;
        padding: {
            right: 90px;
            top: 18px;
        };
        img {
            display: inline-block !important;
            width: 70px !important;
            height: 70px;
            position: absolute;
            right: 0;
            top: 0;
        }
        h3 {
            margin-bottom: 5px;
            font: {
                size: 18px;
                weight: 700;
            };
        }
        span {
            display: block;
            color: $black_blue_color;
        }
    }
    ul {
        padding-right: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
            top: 15px;
        };
        li {
            display: inline-block;
            color: #ff5202;
            margin-left: 2px;
        }
    }
}
.repair-feedback-slides {
    &.owl-theme {
        .owl-dots {
            line-height: .1;
            margin-top: 45px !important;
        }
    }
}

/*=============================
Repair Blog CSS
===============================*/
.single-repair-blog-post {
    .blog-image {
        overflow: hidden;
        position: relative;

        a {
            display: block;

            img {
                transition: all 1.5s ease-out;
            }
        }
        .tags {
            position: absolute;
            right: 15px;
            z-index: 2;
            top: 15px;
            transition: $transition;

            a {
                background: $template_color;
                color: $white_color;
                text-transform: uppercase;
                border-radius: 5px;
                display: inline-block;
                padding: 5px 10px 3px;
                font: {
                    weight: 600;
                    size: 13px;
                }
            }
        }
    }
    .blog-content {
        margin-top: 20px;

        h3 {
            line-height: 1.3;
            margin-bottom: 0;
            font: {
                size: 22px;
                weight: 700;
            };
        }
        ul {
            list-style-type: none;
            margin: {
                top: 15px;
                bottom: 0;
            };
            border: {
                top: 1px solid #eeeeee;
                bottom: 1px solid #eeeeee
            };
            padding: {
                bottom: 15px;
                top: 15px;
                right: 0;
            };
            li {
                display: inline-block;
                color: $paragraph_color;
                font-weight: 600;
                position: relative;
                margin-left: 20px;
                
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 7px;
                    height: 2px;
                    background: $template_color;
                    right: -13px;
                }
                a {
                    display: inline-block;
                    color: $paragraph_color;

                    &:hover, &:focus {
                        color: $template_color;
                    }
                }
                &:first-child {
                    &::before {
                        display: none;
                    }
                }
                &:last-child {
                    float: left;
                    margin-left: 0;

                    &::before {
                        display: none;
                    }
                    a {
                        color: $template_color;
                    }
                }
            }
        }
    }
    &:hover, &:focus {
        .blog-image {
            a {
                img {
                    transform: scale(1.3);
                }
            }
        }
    }
}

/*=============================
Repair Subscribe Area CSS
===============================*/
.repair-subscribe-area {
    position: relative;
    margin: {
        top: -100px;
        bottom: 100px;
    }
}
.repair-subscribe-content {
    background-color: $template_color;
    text-align: center;
    border-radius: 5px;
    padding: {
        top: 40px;
        bottom: 40px;
        left: 30px;
        right: 30px;
    }
    h2 {
        font: {
            size: 28px;
            weight: 600;
        }
        color: $white_color;
        margin-bottom: 30px;
    }
    form {
        overflow: hidden;

        .form-group {
            float: left;
            margin-bottom: 0;
            width: 29%;
            padding-right: 15px;

            .input-newsletter {
                display: block;
                width: 100%;
                border: none;
                border-bottom: 1px solid $white_color;
                background: transparent;
                outline: 0;
                color: $white_color;
                padding: {
                    bottom: 10px;
                    top: 12px;
                }
                &::placeholder {
                    color: $white_color;
                }
            }
        }
        .validation-danger, .validation-success {
            color: $white_color;
        }
        .btn {
            float: left;
            width: 13%;
        }
        .btn-primary {
            color: $white_color;
            background-color: $black_blue_color;
            
            &.disabled, &:disabled {
                color: $white_color;
                background-color: $black_blue_color;
            }
            &:hover, &:focus {
                color: $black_color;
                background-color: $white_color;
            }
        }
    }
}

/*=============================
Footer CSS
===============================*/
.footer-area {
    padding-top: 100px;
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: url(../../assets/img/map2.png);
        opacity: .2;
        background-attachment: fixed;
    }
    &.repair-footer-area {
        position: relative;
        z-index: 1;
        overflow: hidden;
        background-color: transparent;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            height: 100%;
            top: 0;
            width: 201%;
            background: $black_blue_color;
            z-index: -1;
            transform: skewY(177deg);
        }
        &::before {
            display: none;
        }
        .copyright-area {
            background-color: #000000;
        }
        .single-footer-widget {
            .logo {
                a {
                    color: $white_color;
                }
            }
            p {
                color: #f2f2f2;
            }
            h3 {
                color: $white_color;
            }
            .services-list {
                li {
                    a {
                        color: $white_color;

                        &:hover, &:focus {
                            color: $template_color;
                        }
                    }
                }
            }
            .usefull-links {
                li {
                    a {
                        color: $white_color;

                        &:hover, &:focus {
                            color: $template_color;
                        }
                    }
                }
            }
            .contact-list {
                li {
                    color: $white_color;

                    a {
                        color: $white_color;

                        &:hover, &:focus {
                            color: $template_color;
                        }
                    }
                }
            }
        }
        .social-links {
            li {
                a {
                    border: none;
                    color: $white_color;
                    background-color: #343434;
                    line-height: 35px;
                    font-size: 14px;

                    &:hover, &:focus {
                        background-color: $template_color;
                        color: $white_color;
                    }
                }
            }
        }
    }
}
.single-footer-widget {
    margin-bottom: 30px;

    .logo {
        a {
            display: inline-block;
        }
    }
    p {
        color: $black_color;
        margin: {
            top: 15px;
            bottom: 20px;
        };
    }
    h3 {
        text-transform: uppercase;
        margin-bottom: 30px;
        font: {
            size: 22px;
            weight: 700;
        };
    }
    .services-list {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            a {
                margin-bottom: 12px;
                font-weight: 600;
                display: inline-block;

                &:hover {
                    padding-right: 10px;
                }
            }
            &:last-child {
                a {
                    margin-bottom: 0;
                }
            }
        }
    }
    .usefull-links {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            a {
                margin-bottom: 12px;
                font-weight: 600;
                display: inline-block;

                &:hover {
                    padding-right: 10px;
                }
            }
            &:last-child {
                a {
                    margin-bottom: 0;
                }
            }
        }
    }
    .contact-list {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            margin-bottom: 12px;
            font-weight: 500;
            color: $black_color;
            position: relative;
            
            a {
                display: inline-block;
            }
            &:last-child {
                margin-bottom: 0;
            }
            span {
                font-weight: 700;
                display: inline-block;
                margin-left: 2px;
            }
        }
    }
    .working-hours {
        li {
            margin-bottom: 12px;
            font-weight: 500;
            color: $white_color;
            position: relative;
    
            &:last-child {
                margin-bottom: 0;
            }
            a {
                display: inline-block;
            }
            span {
                display: inline-block;
                font-weight: 700;
            }
        }
    }
    .social-links {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            display: inline-block;
            margin-left: 5px;
            
            a {
                border: 1px solid $black_color;
                width: 32px;
                height: 32px;
                text-align: center;
                display: inline-block;
                line-height: 31px;
                border-radius: 50%;
    
                &:hover, &:focus {
                    background: $template_color;
                    color: $white_color;
                    border-color: $template_color;
                }
            }
        }
    }
}
.copyright-area {
	background: $template_color;
    margin-top: 70px;
    padding: {
        top: 30px;
        bottom: 30px;
    };
    p {
        color: $white_color;

        a {
            display: inline-block;
            color: $white_color;
            font-weight: 700;

            &:hover {
                color: $template_color;
            }
        }
    }
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        text-align: left;

        li {
            display: inline-block;
            position: relative;
            padding-left: 20px;

            &::before {
                content: '';
                position: absolute;
                right: -12px;
                top: 4px;
                background: #6f8ba4;
                width: 2px;
                height: 14px;
            }
            &:last-child {
                padding-left: 0;
            }
            &:first-child {
                &::before {
                    display: none;
                }
            }
            a {
                display: inline-block;
                color: $white_color;

                &:hover {
                    color: $template_color;
                }
            }
        }
    }
}

/*=============================
Left Side Modal CSS
===============================*/
.sidebarModal {
    &.modal.left {
        .modal-dialog {
            position: fixed;
            margin: auto;
            width: 420px;
            height: 100%;
            -webkit-transform: translate3d(0%, 0, 0);
            -ms-transform: translate3d(0%, 0, 0);
            -o-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0);
        }
        .modal-content {
            height: 100%;
            overflow-y: auto;
        }
        .modal-body {
            padding: 20px;

            p {
                margin-top: 20px;
            }
            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;
            }
            .modal-contact-info {
                margin: {
                    top: 30px;
                    bottom: 20px;
                };
                li {
                    position: relative;
                    padding-right: 45px;
                    font-size: 18px;
                    margin-bottom: 25px;

                    strong {
                        display: block;
                    }
                    i {
                        position: absolute;
                        right: 0;
                        top: 0;
                        font-size: 35px;
                        color: $template_color;
                    }
                }
            }
            .social-links {
                li {
                    display: inline-block;
                    margin-left: 5px;
                    
                    a {
                        border: 1px solid $black_color;
                        width: 32px;
                        height: 32px;
                        text-align: center;
                        line-height: 30px;
                        border-radius: 50%;
                        display: block;
                        font-size: 14.5px;
            
                        &:hover, &:focus {
                            background: $template_color;
                            color: $white_color;
                            border-color: $template_color;
                        }
                    }
                    &:last-child {
                        margin-left: 0;
                    }
                }
            }
            .logo {
                a {
                    display: inline-block;    
                }
            }
        }
        &.fade {
            .modal-dialog {
                left: -420px;
                -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
                -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
                -o-transition: opacity 0.3s linear, left 0.3s ease-out;
                transition: opacity 0.3s linear, left 0.3s ease-out;
            }
            &.show {
                .modal-dialog {
                    left: 0;
                }
            }
        }
    }
    .modal-content {
        border-radius: 0;
        border: none;
    }
    .modal-header {
        border-bottom-color: transparent;
        background-color: transparent;
        padding: 20px 30px 0;

        .close {
            color: $black_color;
            font-size: 35px;
            outline: 0 !important;
            -moz-transition: $transition;
            -webkit-transition: $transition;
            transition: $transition;
            padding: 0;
            border: 0;
            background-color: transparent;

            &:not(:disabled):not(.disabled){
                &:hover, &:focus{
                    color: $template_color;
                    text-decoration: none;
                    opacity: 1;
                }
            }
        }
    }
}

/*=============================
Go Top CSS
===============================*/
.go-top {
	position: fixed;
	cursor: pointer;
	bottom: 30px;
	left: 0;
	font-size: 25px;
	color: $white_color;
	background-color: $black_color;
	z-index: 1;
	display: none;
	width: 45px;
	text-align: center;
	height: 45px;
	border-radius: 0 10px 10px 0;
	line-height: 46px;
	-moz-transition: $transition;
	-webkit-transition: $transition;
    transition: $transition;

    &:hover {
        color: $white_color;
        background: $template_color;
    }
}

input{
    text-align: right;
}