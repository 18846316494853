@media only screen and (max-width: 767px) {
  body {
    font-size: 14px;
  }
  .preloader-area .spinner {
    margin: -70px auto 0 -40px;
  }
  .ptb-100 {
    padding-top: 55px;
    padding-bottom: 55px;
  }
  p {
    font-size: 14px;
  }
  .btn {
    font-size: 13px;
    padding: 13px 30px;
  }
  .section-title {
    margin-bottom: 40px;
  }
  .section-title span {
    font-size: 15px;
  }
  .section-title h3 {
    line-height: 35px;
    font-size: 25px;
    max-width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .section-title .read-more-btn {
    display: none;
  }
  .section-title p {
    font-size: 15px;
  }
  .form-control {
    height: 45px;
    font-size: 14px;
    padding: 11px 14px;
    text-align: right;
  }
  .form-group {
    margin-bottom: 10px;
    text-align: right;
  }
  .top-header-area {
    text-align: center;
  }
  .top-header-area .header-info {
    display: none;
  }
  .top-header-area .social-links li a {
    margin-right: 6px;
    font-size: 14px;
  }
  .main-banner {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .hero-content h1 {
    font-size: 30px;
    line-height: 1.3;
  }
  .hero-content p {
    font-size: 16px;
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .hero-content .btn {
    margin-left: 10px;
  }
  .video-btn {
    padding-left: 35px;
    font-size: 13px;
  }
  .video-btn i {
    margin-right: 10px;
    font-size: 27px;
    bottom: -4px;
  }
  .hero-image {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    margin-top: 35px;
    padding: 0 15px;
  }
  .main-banner-two {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 70px;
  }
  .main-banner-two .hero-content {
    margin-top: 40px;
  }
  .main-banner-two .banner-image {
    margin-top: 0;
  }
  .banner-image {
    margin-top: 50px;
  }
  .main-banner-three {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 110px;
  }
  .main-banner-three .banner-image {
    margin-top: 0;
    margin-bottom: 40px;
  }
  .owl-theme .owl-nav [class*=owl-] {
    top: auto;
    bottom: 10px;
    right: 15px;
  }
  .owl-theme .owl-nav [class*=owl-].owl-next {
    right: auto;
    left: 15px;
  }
  .homepage-slides-wrapper .main-banner-three .banner-image {
    margin-top: 40px;
    margin-bottom: 0;
  }
  .main-banner-four {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 110px;
  }
  .main-banner-four .banner-image {
    margin-top: 0;
    margin-bottom: 0;
  }
  .single-box {
    padding: 15px;
    margin-bottom: 30px;
  }
  .single-box .icon {
    width: 70px;
    height: 70px;
    font-size: 30px;
    line-height: 70px;
  }
  .single-box h3 {
    font-size: 19px;
    margin-top: 25px;
    margin-bottom: 15px;
  }
  .about-image {
    margin-top: 30px;
  }
  .about-text {
    margin-top: 40px;
    padding-right: 0;
  }
  .about-text span {
    font-size: 14px;
  }
  .about-text h3 {
    font-size: 23px;
    margin: 15px 0 20px;
    line-height: 30px;
  }
  .about-text p {
    margin-bottom: 15px;
  }
  .circle-pattern-2 {
    top: -10px;
    bottom: auto;
  }
  .rocket {
    display: none;
  }
  .vector {
    display: none;
  }
  .about-inner-area {
    margin-top: 0;
  }
  .read-more-btn:hover::before, .read-more-btn:focus::before {
    right: -10px;
  }
  .repair-about-image img {
    width: 100%;
  }
  .single-repair-blog-post .blog-image a img {
    width: 100%;
  }
  .page-title {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .page-title h3 {
    font-size: 25px;
    margin-bottom: 13px;
  }
  .page-title ul li {
    font-size: 15px;
  }
  .services-area {
    padding-bottom: 25px;
  }
  .single-services {
    padding: 15px;
  }
  .single-services .icon {
    width: 70px;
    height: 70px;
    font-size: 35px;
    line-height: 71px;
  }
  .single-services h3 {
    font-size: 19px;
    margin-bottom: 15px;
    margin-top: 25px;
  }
  .read-more-btn {
    font-size: 14px;
  }
  .services-box {
    padding: 15px;
  }
  .services-box .icon {
    width: 70px;
    height: 70px;
    line-height: 71px;
    font-size: 35px;
    margin: 18px 0 18px;
  }
  .services-box .icon::before {
    width: 106px;
    height: 106px;
    top: -19px;
    right: -19px;
  }
  .services-box h3 {
    font-size: 19px;
  }
  .features-text {
    margin-top: 0;
  }
  .features-text h3 {
    font-size: 23px;
    margin-bottom: 18px;
    line-height: 1.3;
  }
  .features-text ul {
    margin: 20px 0 25px;
  }
  .features-text ul li {
    margin-bottom: 12px;
    padding-right: 29px;
    font-size: 14px;
  }
  .features-area .ptb-100 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .features-area .img {
    margin-bottom: 35px;
  }
  .features-text {
    margin-top: 0;
    margin-bottom: 40px;
  }
  .features-area-two {
    padding-bottom: 25px;
  }
  .single-features {
    margin-bottom: 30px;
  }
  .single-features i {
    width: 70px;
    height: 70px;
    font-size: 30px;
    line-height: 72px;
  }
  .single-features h3 {
    font-size: 19px;
  }
  .features-area-three {
    padding-bottom: 25px;
  }
  .check-your-website-content h2 {
    font-size: 25px;
    margin-bottom: 15px;
  }
  .check-your-website-content p {
    font-size: 15px;
  }
  .check-your-website-content .form-control {
    border-radius: 40px;
    height: 50px;
    margin-bottom: 15px;
  }
  .check-your-website-content .btn {
    margin-top: 0;
    padding: 15px 40px;
    display: block;
    width: 100%;
  }
  .boxes-area .owl-thumb-item {
    width: 100%;
  }
  .box {
    height: 90px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .box span {
    font-size: 85px;
  }
  .box h3 {
    font-size: 19px;
    line-height: 24px;
  }
  .features-box {
    margin-bottom: 30px;
    padding-right: 70px;
  }
  .features-box i {
    width: 50px;
    height: 50px;
    font-size: 26px;
    line-height: 53px;
  }
  .features-box h3 {
    font-size: 19px;
    line-height: 22px;
    margin-bottom: 15px;
  }
  .features-box p {
    margin-bottom: 10px;
  }
  .working-process-area {
    padding-bottom: 25px;
  }
  .single-work-process {
    padding: 15px;
    margin-bottom: 30px;
  }
  .single-work-process::before {
    display: none;
  }
  .single-work-process h3 {
    font-size: 19px;
    margin-top: 25px;
  }
  .single-work-process .icon {
    width: 70px;
    height: 70px;
    font-size: 35px;
    line-height: 74px;
  }
  .analysis-form {
    padding: 15px;
  }
  .analysis-form h3 {
    font-size: 20px;
    margin-bottom: 25px;
  }
  .analysis-form form .btn {
    padding: 11px 40px;
  }
  .analysis-text {
    margin-top: 40px;
    padding-right: 0;
  }
  .analysis-text span {
    font-size: 15px;
  }
  .analysis-text h3 {
    font-size: 25px;
    margin: 10px 0 15px;
    line-height: 33px;
  }
  .analysis-text p {
    font-size: 15px;
  }
  .analysis-text ul li {
    font-size: 14px;
  }
  .shorting-menu {
    display: block;
    padding: 10px 10px 5px;
    margin-bottom: 35px;
    border-radius: 5px;
    text-align: center;
  }
  .shorting-menu .filter {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .single-work {
    margin-top: 5px;
  }
  .single-work .work-content h4 {
    font-size: 19px;
    margin-bottom: 8px;
  }
  .single-work .work-content ul li {
    font-size: 14px;
  }
  .single-work .popup-btn, .single-work .link-btn {
    right: 20px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
  }
  .single-work:hover .popup-btn, .single-work:hover .link-btn {
    top: 20px;
  }
  .single-work:hover .link-btn {
    left: 20px;
  }
  .single-feedback {
    padding: 20px 15px;
    margin-top: 24px;
  }
  .single-feedback .client-info {
    padding-right: 117px;
    margin-bottom: 25px;
  }
  .single-feedback .client-info h4 {
    font-size: 19px;
  }
  .single-feedback .client-info span {
    font-size: 14px;
    margin-top: 8px;
  }
  .owl-theme .owl-dots {
    margin-top: 10px;
  }
  .team-area {
    padding-bottom: 25px;
  }
  .single-team {
    padding: 15px;
  }
  .single-team .team-content h3 {
    font-size: 19px;
  }
  .single-team .team-content span {
    font-size: 14px;
  }
  .single-team .team-content ul li a {
    font-size: 14px;
  }
  .funfacts-area {
    padding-bottom: 25px;
  }
  .fun-fact {
    margin-bottom: 30px;
    padding-right: 0;
    text-align: center;
  }
  .fun-fact i {
    font-size: 35px;
    position: relative;
    right: 0;
    top: 0;
  }
  .fun-fact p {
    font-size: 15px;
    margin-top: 10px;
  }
  .fun-fact h3 {
    font-size: 30px;
    margin-top: 8px;
  }
  .tab-slider-trigger {
    padding: 10px 25px;
    font-size: 14px;
  }
  .tab-slider-container .row {
    width: auto;
  }
  .tab-slider-nav {
    margin-bottom: 10px;
  }
  .pricingTable {
    padding-bottom: 25px;
    margin-top: 30px;
  }
  .pricingTable .pricingTable-header {
    padding: 25px 15px 35px;
    z-index: 1;
  }
  .pricingTable .pricingTable-header::before {
    z-index: -1;
  }
  .pricingTable .title {
    font-size: 19px;
  }
  .pricingTable .price-value {
    font-size: 30px;
    margin-bottom: 25px;
  }
  .pricingTable .price-value span {
    font-size: 13px;
    margin-right: -5px;
  }
  .pricingTable .pricing-content li {
    font-size: 14px;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  .pricing-plan {
    margin-top: 30px;
    padding-bottom: 25px;
  }
  .pricing-plan h3 {
    font-size: 19px;
    padding-top: 25px;
  }
  .pricing-plan h3 span {
    height: 85px;
    width: 85px;
    margin: 20px auto -65px;
    line-height: 90px;
    font-size: 25px;
  }
  .pricing-plan ul {
    margin: 70px 0 25px;
  }
  .pricing-plan li {
    padding: 10px 0;
    font-size: 14px;
  }
  .pricing-plan#most-popular {
    top: 0;
    padding: 25px 0 25px;
  }
  .single-blog-item span {
    font-size: 14px;
  }
  .single-blog-item h4 {
    font-size: 19px;
  }
  .newsletter {
    max-width: 100%;
    padding: 20px 15px;
    border-radius: 5px;
  }
  .newsletter form .form-control {
    height: 70px;
    border-radius: 5px;
    padding-right: 18px;
    font-size: 16px;
  }
  .newsletter form .btn {
    position: relative;
    right: 0;
    top: 0;
    height: 55px;
    width: 100%;
    margin-top: 10px;
  }
  .newsletter h2 {
    margin-bottom: 25px;
    font-size: 23px;
  }
  .timeline::before {
    right: 10px;
  }
  .timeline > li {
    margin-bottom: 0;
    position: relative;
    width: 100%;
    float: right;
    clear: right;
  }
  .timeline > li > .timeline-panel {
    width: calc(100% - 50px);
    width: -moz-calc(100% - 50px);
    width: -webkit-calc(100% - 50px);
  }
  .timeline > li > .timeline-badge {
    right: -2px;
    margin-right: 0;
    top: 25px;
  }
  .timeline > li > .timeline-panel {
    float: left;
    padding: 20px 15px;
    text-align: right;
  }
  .timeline > li > .timeline-panel::before {
    border-right-width: 0;
    border-left-width: 15px;
    right: -15px;
    left: auto;
  }
  .timeline > li > .timeline-panel::after {
    border-right-width: 0;
    border-left-width: 14px;
    right: -14px;
    left: auto;
  }
  .timeline > li.timeline-inverted {
    float: right;
    clear: right;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .timeline > li.timeline-inverted .timeline-footer {
    text-align: left;
  }
  .timeline > li.timeline-inverted > .timeline-badge {
    right: -2px;
  }
  .timeline .timeline-heading h3 {
    font-size: 19px;
  }
  .timeline .timeline-body > p {
    font-size: 14px;
    margin-bottom: 40px;
  }
  .single-blog-item {
    padding: 15px;
  }
  .single-blog-item h3 {
    font-size: 19px;
  }
  .single-blog-post .blog-post-content span {
    font-size: 14px;
  }
  .single-blog-post .blog-post-content h3 {
    font-size: 19px;
  }
  .blog-details .date {
    padding: 9px 19px;
    font-size: 19px;
  }
  .blog-details-heading h3 {
    font-size: 19px;
    line-height: 26px;
  }
  .blog-details-content p {
    font-size: 14px;
  }
  .blog-details-content .blockquote {
    padding: 20px;
  }
  .blog-details-content .blockquote p {
    font-size: 16px;
  }
  .blog-details-content .blockquote .blockquote-footer {
    font-size: 16px;
  }
  .blog-details-content .title {
    font-size: 19px;
    margin-bottom: 30px;
  }
  .blog-details-meta ul .title {
    font-size: 16px;
  }
  .blog-details-meta .tags li a {
    padding: 4px 13px;
    font-size: 14px;
  }
  .blog-details-meta .share {
    float: none;
    margin-top: 20px;
  }
  .blog-details-meta .share li a {
    width: 30px;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
  }
  .blog-details-comments .single-comments {
    padding-right: 120px;
  }
  .blog-details-comments .single-comments .content h4 {
    font-size: 17px;
  }
  .blog-details-comments .single-comments .content span {
    font-size: 14px;
    margin: 8px 0 15px;
  }
  .single-comments.left-mr {
    margin-right: 20px;
    margin-top: 35px;
  }
  .blog-details-comments-form .btn {
    margin-top: 10px;
  }
  .services-details-overview {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 40px;
  }
  .services-details-overview .services-details-desc {
    flex: 0 0 100%;
    order: 1;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .services-details-overview .services-details-desc h3 {
    font-size: 20px;
  }
  .services-details-overview .services-details-desc .features-text {
    margin-bottom: 0;
  }
  .services-details-overview .services-details-image {
    flex: 0 0 100%;
    order: 2;
    max-width: 100%;
    margin-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .contact-box {
    padding: 20px 80px 20px 15px;
    margin-bottom: 30px;
  }
  .contact-box .icon {
    right: 15px;
    top: 20px;
    width: 45px;
    height: 45px;
    line-height: 44px;
    font-size: 20px;
  }
  .contact-box .content h4 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .contact-box .content p {
    font-size: 14px;
  }
  .contact-text h3 {
    font-size: 19px;
  }
  .contact-text .social-links {
    margin-bottom: 30px;
  }
  #map {
    margin-top: 0;
    margin-bottom: 30px;
  }
  #contactForm .btn {
    padding: 13px 40px;
    font-size: 13px;
  }
  .error {
    max-width: 100%;
  }
  .error h1 {
    font-size: 130px;
  }
  .error p {
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 25px;
  }
  .project-details h3 {
    font-size: 19px;
  }
  .project-details p {
    font-size: 14px;
  }
  .project-details .features {
    margin-top: 25px;
  }
  .project-details ul.features li {
    margin-bottom: 12px;
    padding-left: 24px;
    font-size: 14px;
  }
  .project-info {
    margin-top: 30px;
  }
  .project-info ul li {
    padding-right: 40px;
    margin-bottom: 15px;
    font-size: 14px;
  }
  .project-info ul li i {
    font-size: 25px;
  }
  .project-info ul li span {
    font-size: 17px;
  }
  .pagination-area {
    margin-top: 25px;
  }
  .page-link {
    padding: 10px 18px;
    font-size: 14px;
  }
  .side-bar {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .side-bar .widget-box {
    padding: 25px 20px;
  }
  .side-bar .widget-box .title {
    font-size: 19px;
    margin-bottom: 30px;
  }
  .side-bar .categories-box ul li a {
    margin-bottom: 12px;
    font-size: 14px;
  }
  .side-bar .tags-box ul li a {
    padding: 4px 14px;
    font-size: 14px;
  }
  .side-bar .tags-box .title {
    margin-bottom: 24px;
  }
  .recent-post-desc {
    margin-top: -5px;
  }
  .repair-main-banner {
    padding-top: 155px;
    padding-bottom: 245px;
  }
  .repair-banner-content p {
    font-size: 15px;
  }
  .repair-banner-content h2 {
    line-height: 1.2;
    font-size: 30px;
  }
  .repair-boxes-area::before {
    transform: skewY(-170deg);
  }
  .single-repair-box h3 {
    font-size: 18px;
  }
  .single-repair-box img {
    width: unset;
  }
  .repair-about-image {
    padding-bottom: 30px;
  }
  .repair-about-image img:last-child {
    position: relative;
    bottom: 0;
    border: none;
    left: 0;
    margin-top: 20px;
  }
  .single-repair-services {
    padding: 20px;
  }
  .single-repair-services h3 {
    font-size: 19px;
  }
  .repair-services-inner {
    margin-top: 50px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .repair-services-inner .col-lg-4:nth-child(2) {
    border-left: none;
    border-right: none;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .single-repair-feedback {
    padding: 20px;
  }
  .single-repair-feedback .client-info span {
    font-size: 14px;
  }
  .gallery-area {
    padding-bottom: 25px;
  }
  .repair-blog-area {
    padding-bottom: 25px;
  }
  .single-repair-blog-post {
    margin-bottom: 30px;
  }
  .single-repair-blog-post .blog-content h3 {
    font-size: 19px;
    line-height: 1.4;
  }
  .repair-subscribe-area {
    margin-top: -55px;
    margin-bottom: 55px;
  }
  .repair-subscribe-content {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .repair-subscribe-content h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .repair-subscribe-content form .form-group {
    float: unset;
    margin-bottom: 10px;
    width: 100%;
    padding-left: 0;
  }
  .repair-subscribe-content form .btn {
    float: unset;
    width: 100%;
  }
  .single-repair-blog-post .blog-content ul {
    overflow: hidden;
  }
  .footer-area {
    padding-top: 55px;
  }
  .single-footer-widget {
    margin-bottom: 30px;
  }
  .single-footer-widget .logo a {
    font-size: 25px;
  }
  .single-footer-widget h3 {
    font-size: 18px;
    margin-bottom: 25px;
  }
  .single-footer-widget .services-list li a {
    margin-bottom: 12px;
    font-size: 14px;
  }
  .single-footer-widget .usefull-links li a {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .single-footer-widget .contact-list li {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .copyright-area {
    text-align: center;
    margin-top: 25px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .copyright-area ul {
    text-align: center;
    margin-top: 15px;
  }
  .copyright-area ul li a {
    font-size: 14px;
  }
  .go-top {
    bottom: 20px;
    font-size: 20px;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptb-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .section-title .read-more-btn {
    display: none;
  }
  .top-header-area .header-info li:last-child {
    display: none;
  }
  .main-banner {
    height: 100%;
    padding-top: 110px;
    padding-bottom: 110px;
  }
  .hero-content h1 {
    font-size: 45px;
    line-height: 1.3;
  }
  .hero-content p {
    font-size: 17px;
  }
  .circle-pattern-2 {
    display: none;
  }
  .main-banner-two {
    height: 100%;
    padding-top: 110px;
    padding-bottom: 110px;
  }
  .main-banner-two .hero-content {
    margin-top: 50px;
  }
  .main-banner-two .banner-image {
    margin-top: 0;
  }
  .banner-image img {
    width: 60% !important;
  }
  .hero-image {
    position: relative;
    left: 0;
    width: auto;
    bottom: 0;
    margin-top: 35px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .main-banner-three {
    height: 100%;
    padding-top: 110px;
    padding-bottom: 110px;
  }
  .main-banner-three .banner-image {
    margin-top: 0;
    margin-bottom: 40px;
  }
  .homepage-slides-wrapper .main-banner-three .banner-image {
    margin-top: 40px;
    margin-bottom: 0;
  }
  .main-banner-four {
    height: 100%;
    padding-top: 110px;
    padding-bottom: 110px;
  }
  .main-banner-four .banner-image {
    margin-bottom: 40px;
  }
  .features-area-two {
    padding-bottom: 50px;
  }
  .single-features {
    margin-bottom: 30px;
  }
  .features-area-three {
    padding-bottom: 35px;
  }
  .features-text {
    margin-top: 0;
    position: relative;
    z-index: 1;
  }
  .features-text h3 {
    font-size: 25px;
    line-height: 34px;
  }
  .features-text ul li {
    font-size: 15px;
  }
  .features-area .img {
    position: relative;
    z-index: 1;
    margin-top: 60px;
  }
  .single-work-process::before {
    display: none;
  }
  .single-work-process .icon {
    width: 65px;
    height: 65px;
    font-size: 30px;
    line-height: 70px;
  }
  .single-work-process h3 {
    font-size: 20px;
  }
  .team-area {
    padding-bottom: 50px;
  }
  .services-area {
    padding-bottom: 50px;
  }
  .single-box {
    margin-bottom: 30px;
  }
  .about-image {
    margin-top: 40px;
  }
  .about-text {
    margin-top: 50px;
    padding-right: 0;
    position: relative;
    z-index: 1;
  }
  .contact-box {
    margin-bottom: 20px;
  }
  .analysis-text {
    margin-top: 40px;
    padding-right: 0;
  }
  .funfacts-area {
    padding-bottom: 40px;
  }
  .fun-fact {
    margin-bottom: 40px;
  }
  .pricingTable {
    margin-top: 30px;
  }
  .tab-slider-nav {
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
  }
  .timeline > li > .timeline-badge {
    left: -26px;
  }
  .timeline > li.timeline-inverted > .timeline-badge {
    right: -26px;
  }
  .pricing-plan {
    margin-top: 30px;
  }
  .pricing-plan#most-popular {
    top: 0;
    padding: 0 0 20px;
    z-index: 2;
  }
  .pricing-plan#most-popular h3 {
    margin-top: 0;
    padding-top: 20px;
  }
  .side-bar {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .repair-banner-content h2 {
    font-size: 45px;
  }
  .repair-about-content {
    margin-top: 40px;
  }
  .repair-services-inner .col-lg-4:nth-child(2) {
    border: none;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .gallery-area {
    padding-bottom: 50px;
  }
  .repair-blog-area {
    padding-bottom: 50px;
  }
  .single-repair-blog-post {
    margin-bottom: 30px;
  }
  .repair-subscribe-content form .form-group {
    float: right;
    margin-bottom: 0;
    width: 33%;
    padding-left: 15px;
  }
  .repair-subscribe-content form .btn {
    width: 25%;
    margin-top: 15px;
  }
  .products-details {
    margin-top: 20px;
  }
  .shorting-menu .filter {
    font-size: 15px;
  }
  .analysis-text {
    padding-right: 0;
  }
  .fun-fact {
    padding-right: 0;
    text-align: center;
  }
  .fun-fact i {
    display: inline-block;
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    margin-bottom: 15px;
  }
  .footer-area {
    padding-top: 80px;
  }
  .single-footer-widget {
    margin-bottom: 30px;
  }
  .copyright-area {
    margin-top: 50px;
  }
  .copyright-area ul li a {
    font-size: 15px;
  }
  .copyright-area p {
    font-size: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-light .others-option .call-us {
    display: none;
  }
  .main-banner-three .hero-content {
    margin-top: 0;
  }
  .main-banner-four .hero-content {
    margin-top: 0;
  }
  .main-banner-two .hero-content {
    margin-top: 70px;
  }
  .hero-image {
    left: -195px;
    bottom: 30px;
    width: 660px;
  }
  .hero-content h1 {
    font-size: 50px;
  }
  .services-box h3 {
    font-size: 21px;
  }
  .single-services h3 {
    font-size: 21px;
  }
  .circle-pattern-2 {
    display: none;
  }
  .about-text {
    margin-top: 80px;
    padding-right: 0;
    position: relative;
    z-index: 1;
  }
  .features-area .about-text {
    margin-top: 0;
  }
  .features-box h3 {
    line-height: 28px;
  }
  .contact-box {
    padding: 20px 80px 20px 20px;
  }
  .contact-box .icon {
    right: 15px;
  }
  .box h3 {
    font-size: 20px;
    line-height: 28px;
  }
  .timeline > li > .timeline-badge {
    left: -31px;
  }
  .timeline > li.timeline-inverted > .timeline-badge {
    right: -31px;
  }
  .repair-subscribe-content form .form-group {
    width: 33%;
    padding-left: 15px;
  }
  .repair-subscribe-content form .btn {
    width: 18%;
    margin-top: 20px;
  }
}
@media only screen and (min-width: 1550px) {
  .hero-image {
    left: 0;
    bottom: 90px;
    width: 830px;
  }
  .circle-pattern-2 {
    height: 55em;
    width: 60em;
  }
  .rocket {
    left: -120px;
    bottom: 20rem;
  }
}